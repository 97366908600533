import React, { Suspense, lazy } from 'react'

import '../styles/nav.scss'

import useWindowDimesions from '../components/useWindowDimesions'
import SplashScreen from './SplashScreen'
import { Helmet } from 'react-helmet'

// import Footer from '../components/Footer'
const Footer = lazy(() => import('../components/Footer'))
// import Hero from '../components/Hero'
const Hero = lazy(() => import('../components/Hero'))

// import MobileNavBar from '../components/MobileNavBar'
const MobileNavBar = lazy(() => import('../components/MobileNavBar'))
// import DesktopNavBar from '../components/DesktopNavBar'
const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))
// import KaunBanegaHomeopath from './KaunBanegaHomeopath/KBH'
const KaunBanegaHomeopath = lazy(() => import('./KaunBanegaHomeopath/KBH'))
// import CourseGrid from '../components/CourseGrid'
const CourseGrid = lazy(() => import('../components/CourseGrid'))

const KBH = () => {
  const { width } = useWindowDimesions()

  if (width < 999) {
    return (
      <>
        <Helmet>
          <title>Be a Homeopath | E-Learning @ SHAH</title>
          <meta
            name='description'
            content='Be a Homeopath - Explore E-Learning @ SHAH, Samuel Hahnemann Academy for Homeopathy.'
          />
          <meta name='keywords' content='Be a Homeopath, E-Learning, Homeopathy, Samuel Hahnemann Academy, SHAH' />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/',
              description: 'E-Learning @ SHAH - Explore the world of Homeopathy with Samuel Hahnemann Academy.',
              keywords: 'E-Learning, Homeopathy, Samuel Hahnemann Academy, SHAH'
              // Add more structured data properties as needed
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <MobileNavBar />

          <Hero title='Be a Homeopath' img='/images/kbh-new-hero-banner.jpg' />

          <KaunBanegaHomeopath />
          <CourseGrid gridTitle='Courses' category='kbh' />
          <Footer />
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>Be a Homeopath | E-Learning @ SHAH</title>
          <meta
            name='description'
            content='Be a Homeopath - Explore E-Learning @ SHAH, Samuel Hahnemann Academy for Homeopathy.'
          />
          <meta name='keywords' content='Be a Homeopath, E-Learning, Homeopathy, Samuel Hahnemann Academy, SHAH' />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/',
              description: 'E-Learning @ SHAH - Explore the world of Homeopathy with Samuel Hahnemann Academy.',
              keywords: 'E-Learning, Homeopathy, Samuel Hahnemann Academy, SHAH'
              // Add more structured data properties as needed
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <div className='desktop'>
            <DesktopNavBar />

            <Hero title='Be a Homeopath' img='/images/kbh-new-hero-banner.jpg' />
            <div style={{ margin: '0px 10%' }}>
              <KaunBanegaHomeopath />
            </div>
            <CourseGrid gridTitle='Courses' category='kbh' />
            <div style={{ marginTop: '20rem' }}></div>
            <div style={{ marginTop: -141 }}>
              <Footer />
            </div>
          </div>
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  }
}

export default KBH
