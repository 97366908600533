import React, { Suspense, lazy } from 'react'

import useWindowDimesions from '../components/useWindowDimesions'

// import Footer from '../components/Footer'
const Footer = lazy(() => import('../components/Footer'))
// import Hero from '../components/Hero'
const Hero = lazy(() => import('../components/Hero'))
// import MobileNavBar from '../components/MobileNavBar'
const MobileNavBar = lazy(() => import('../components/MobileNavBar'))
// import DesktopNavBar from '../components/DesktopNavBar'
const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))
// import AboutPageShah from '../Pages/AboutPageShah'
const PostInauguralFelicitationspage = lazy(() => import('../Pages/Post-Inaugural-Felicitations-page'))
import SplashScreen from './SplashScreen'
import { Helmet } from 'react-helmet'
const AboutShah = () => {
  const { width } = useWindowDimesions()

  if (width < 799) {
    return (
      <>
        <Helmet>
          <title>E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy - About</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <MobileNavBar />
          <Hero title='Post Inaugural Felicitations' img='/images/WebBanner.jpg' />
          <PostInauguralFelicitationspage />
          <Footer />
        </Suspense>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy - About</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <div className='desktop'>
            <DesktopNavBar />

            <Hero title='Post Inaugural Felicitations' img='/images/WebBanner.jpg' />
            <PostInauguralFelicitationspage />
            <div>
              <Footer />
            </div>
          </div>
        </Suspense>
      </>
    )
  }
}

export default AboutShah
