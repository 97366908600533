/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './index.scss'
import DangerousHTML from 'react-dangerous-html'
// import { Widget } from '@typeform/embed-react'
import {
  ShowMyChapters,
  ShowMyCoursePageLessons
  // ShowMyCoursePageQuiz
} from '../../components/ShowMyChapters'
import GridComponent from './GridComponent'
import AppChapterDropdown from './AppChapterDropdown'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import VideoPlayer from '../../components/VideoPlayer'
import { RWebShare } from 'react-web-share'
import TypoForm from '../../components/typoForm'
import { callApi } from '../../utils/api-utils'
import { getCookie } from '../../utils/getCookie'
import SplashScreen from '../SplashScreen'

const defaults = {
  methods: {
    PATCH: {
      method: 'PATCH'
    },
    PUT: {
      method: 'PUT'
    },
    POST: {
      method: 'POST'
    }
  }
}

const allChapterEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/course/all-chapters'
}

const createOrderEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/Payment/orders'
}
const successOrderEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/Payment/success'
}

async function OpenRazorPay(props) {
  const {
    payment,
    coursename,

    email,
    name,

    course_id,
    mongoId,
    img,
    courseUrl,
    date,
    slug,
    config,
    token,
    chapter_id,
    chaptername,
    couponCode,
    coinValue,
    category
  } = props

  const test = process.env.REACT_APP_RAZOR_PAY_KEY

  const orderBody = {
    course_id: course_id,
    student_id: mongoId,
    coupon: couponCode || null,
    chapter_id: chapter_id || null,
    coin: Number(coinValue) || null
  }

  const body = {
    studentid: mongoId,
    studentname: name,
    studentemail: email,
    courseid: course_id,
    coursename: coursename,
    image: img,
    status: 'success',
    slug: slug,
    url: courseUrl,
    category: category === 'Indian Students Only' ? 'indian' : 'international',
    currency: category === 'Indian Students Only' ? 'INR' : 'USD',
    amount: payment,
    Date: date,
    chapterid: chapter_id || null,
    chaptername: chaptername || null,
    is_website: true
  }

  const finalObj = { ...body, ...orderBody }

  await callApi({ uriEndPoint: createOrderEndPoint, body: finalObj })
    .then(response => {
      const { addOrder, order } = response

      const { amount, id: order_id, currency } = order

      const { _id: add_order_id } = addOrder

      var options = {
        key: test, // Enter the Key ID generated from the Dashboard
        amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: currency,
        name: 'E-Learning @ SHAH ',
        order_id: order_id,
        description: `For buying this ${coursename} course`,
        handler: async response => {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            add_order_id: add_order_id
          }
          await callApi({ uriEndPoint: successOrderEndPoint, body: data })
            .then(async () => {
              const orderBody = {
                studentid: mongoId,
                studentname: name,
                studentemail: email,
                courseid: course_id,
                coursename: coursename,
                image: img,
                status: 'success',
                slug: slug,
                url: courseUrl,
                category: category === 'Indian Students Only' ? 'indian' : 'international',
                currency: category === 'Indian Students Only' ? 'INR' : 'USD',
                amount: payment,
                Date: date,
                chapterid: chapter_id || null,
                chaptername: chaptername || null,
                order_id: add_order_id
              }
              await axios
                .post('/api/order/update-order-status', orderBody, {
                  headers: {
                    Authorization: 'Bearer' + ' ' + token
                  }
                })
                .then(async () => {
                  // const stringifyDataBuyCourse = JSON.stringify(res.data.data)
                  // await axios.get('/api/order/buy-course-list', config).then(async res => {
                  //   // const stringifyDataBuyCourse = JSON.stringify(res.data.data)

                  //   // await localStorage.setItem('buyCourses', stringifyDataBuyCourse)
                  // })
                  window.location.href = '/purchased-course'

                  // await localStorage.setItem('buyCourses', stringifyDataBuyCourse)
                })
                .catch(err => console.log('api error', err))
            })
            .catch(e => console.log(e))

          alert('Payment success')
        },

        notes: {
          address: 'Samuel Hahnemann Academy for Homeopathy - SHAH, Anand - 388001 , Gujarat, India'
        },
        theme: {
          color: '#FF5722'
        }
      }

      const rzp1 = new window.Razorpay(options)
      rzp1.open()
    })
    .catch(e => console.log(e))
}

export default function MobileViewAppCoursePage(props) {
  const selectedOption = getCookie('selectedOption')
  const currentUserInformation = localStorage.getItem('profileInformation')
  const mongoId = localStorage.getItem('mongoId')
  const token = localStorage.getItem('accessToken')
  const referralID = localStorage.getItem('referral_code')

  const [category, setCategory] = useState('')

  const [categoryError, setCategoryError] = useState(false)
  const [tab, setTab] = useState('Overview')
  const [Data, setData] = useState([])
  const currentUserInformationParse = JSON.parse(currentUserInformation)

  const [id, setId] = useState('')

  const [modalIsOpen, setIsOpen] = useState(false)

  const [couponCode, setCouponCode] = useState(null)

  const [discountPrice, setDiscountPrice] = useState(null)

  const [checkedBuyCourse, setCheckedBuyCourse] = useState(false)

  const [selectedCourse, setSelectedCourse] = useState('full_course')
  const [selectedChapter, setSelectedChapter] = useState(null)

  const [selectChapterData, setSelectChapterData] = useState(null)

  const [allChapter, setAllChapter] = useState([])

  const [onChangeChapterViseData, setOnChangeChapterViseData] = useState(false)
  const [coinValue, setCoinValue] = useState(null)
  const [wait, setWait] = useState(true)

  const [videoOtp, setVideoOtp] = useState({})
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  let { slug } = props
  let config = {
    headers: {
      Authorization: 'Bearer' + ' ' + token
    },
    params: {
      studentid: mongoId
    }
  }

  async function getData() {
    if (mongoId) {
      try {
        const response = await axios.get('/api/order/buy-course-list', config)
        const buyCoursesData = response.data.data
        const filterData = buyCoursesData.filter(i => i.slug === slug)
        let checkValue = false
        filterData?.map(item => {
          if (item?.courseid && item?.chapterid !== null) {
            checkValue = false
          } else {
            checkValue = true
          }
        }) || null

        setCheckedBuyCourse(checkValue)
      } catch (error) {
        console.error('Error fetching buy course list:', error)
      }
    }
  }

  async function FetchData() {
    await axios
      .get('/api/course?type=front-end-api')
      .then(res => {
        res?.data?.courses.forEach(element => {
          if (element.slug === 'Theory1') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Theory1.pdf'
          }

          if (element.slug === 'Practical1') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Practical1.pdf'
          }
          if (element.slug === 'Practical2') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Practical2.pdf'
          }
          if (element.slug === 'Practical3') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Practical3.pdf'
          }
          if (element.slug === 'Totalpractical') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Totalpractical.pdf'
          }
          if (element.slug === 'Fulldigital') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Fulldigital.pdf'
          }
          if (element.slug === 'Basic') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Basic.pdf'
          }
          if (element.slug === 'Complete') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Complete.pdf'
          }
          if (element.slug === 'Advanced') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Advanced.pdf'
          }
          if (element.slug === 'Prescription') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Prescription.pdf'
          }
          if (element.slug === 'Acute') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Acute.pdf'
          }
          if (element.slug === 'Total') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Total.pdf'
          }
          if (element.slug === 'Dangerousfuture') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Dangerousfuture.pdf'
          }
          if (element.slug === 'Femalehomeopathy') {
            element.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Femalehomeopathy.pdf'
          }
        })
        const FilterCourse = res.data.courses.filter(r => r.slug === slug)
        setData(FilterCourse)
        setId(FilterCourse[0]._id)
      })
      .catch(e => alert(e))
  }

  function PayButton(payment, paymentD, homePay, coursename, res) {
    const fireid = '122323232'
    if (currentUserInformationParse) {
      const email = currentUserInformationParse.email
      const name = currentUserInformationParse.legal_name
      const phone = currentUserInformationParse.phone
      const course_id = id
      const img = res?.Img
      const courseUrl = window.location.href
      const date = new Date().toString()
      const chapter_id = selectChapterData?._id
      const chaptername = selectChapterData?.chapterName

      if (category === 'Indian Students Only') {
        OpenRazorPay({
          payment,
          coursename,
          fireid,
          email,
          name,
          phone,
          course_id,
          mongoId,
          img,
          courseUrl,
          date,
          slug,
          config,
          token,
          price: res.SellingPrice,
          chapter_id,
          chaptername,
          couponCode,
          coinValue,
          category
        })
      }
      if (category === 'International Students' || id === 1) {
        OpenRazorPay({
          paymentD,
          coursename,
          fireid,
          email,
          name,
          phone,
          course_id,
          mongoId,
          img,
          courseUrl,
          date,
          slug,
          config,
          token,
          price: res.HomeopathicSellingPrice,
          chapter_id,
          chaptername,
          couponCode,
          coinValue,
          category
        })
      }
      if (category === 'Homeopathic Practitioner' || id === 1) {
        OpenRazorPay({
          homePay,
          coursename,
          fireid,
          email,
          name,
          phone,
          course_id,
          mongoId,
          img,
          courseUrl,
          date,
          slug,
          config,
          token,
          price: res.DollarSellingPrice,
          chapter_id,
          chaptername,
          couponCode,
          coinValue,
          category
        })
      }
      if (category === 'error') {
        setCategoryError(true)
        setCategory('error')
      }
      if (category === '') {
        setCategoryError(true)
        setCategory('error')
      }
    } else {
      window.location.href = '/signup'
    }
  }

  function openModal(e) {
    localStorage.setItem('overview', e)

    const url = e

    const baseUrl = 'https://player.vdocipher.com/v2'
    const queryString = new URL(url).search
    const params = new URLSearchParams(queryString)

    const filteredParams = {}
    for (const [key, value] of params) {
      if (!url.startsWith(baseUrl) || key !== '') {
        filteredParams[key] = value
      }
    }

    const filteredQueryString = new URLSearchParams(filteredParams).toString()

    const params1 = new URLSearchParams(filteredQueryString)

    const obj = {}
    for (const [key, value] of params1) {
      obj[key] = value
    }
    setVideoOtp(obj)

    setIsOpen(true)
  }

  const DiscountApi = async () => {
    await axios
      .post('/api/payment/check-coupon', {
        body: 'front-end-api',
        courseId: id,
        coupon: couponCode || null,
        coin: Number(coinValue) || null
      })
      .then(res => {
        setDiscountPrice(res.data)
      })
      .catch(err => console.log('hello', err))
  }

  const checkPrice = async () => {
    if (coinValue && category) {
      await axios
        .post('/api/payment/check-coupon', {
          body: 'front-end-api',
          courseId: id,
          coupon: couponCode || null,
          coin: Number(coinValue) || null
        })
        .then(data => {
          setDiscountPrice(data.data)
        })
        .catch(err => console.log('error', err))
    }
  }

  const allChapterApi = async () => {
    if (id)
      try {
        await callApi({
          uriEndPoint: allChapterEndPoint,
          body: { id: id, body: 'front-end-api' }
        }).then(res => {
          setAllChapter(res)
        })
      } catch (error) {
        console.log(`🚀  ~ file: index.js:461 ~ allChapter ~ error:`, error)
      }
  }

  useEffect(() => {
    if (selectedCourse === 'chapter_vise') {
      allChapterApi()
    }
  }, [selectedCourse])

  const selectChapterOnChange = async value => {
    if (mongoId) {
      try {
        const response = await axios.get('/api/order/buy-course-list', config)
        const buyCoursesData = response.data.data
        const filterData = buyCoursesData.filter(i => i.slug === slug)
        let checkValue = false
        filterData?.map(item => {
          if (item?.chapterid === value) {
            checkValue = true
          }
        })
        setOnChangeChapterViseData(checkValue)

        console.log(`🚀  ~ getData ~ filterData:`, filterData)
      } catch (error) {
        console.error('Error fetching buy course list:', error)
      }
    }

    const filterData = allChapter?.filter(i => i._id === value)[0] || null
    setSelectChapterData(filterData)
  }

  const AllFunctionCall = async () => {
    await FetchData()
    await getData()
    setWait(false)
  }

  useEffect(() => {
    AllFunctionCall()
  }, [])

  if (wait) {
    return <SplashScreen />
  } else {
    return Data.map(res => {
      res.nameOfCourse = res.nameOfCourse
        .replace('Part – A: Theory Part — 1 :', ':')
        .replace('Part – B: Practical Part — 1 :', ':')
        .replace('Part – B: Practical Part — 2:', ':')
        .replace('Part – B: Practical Part — 3 :', ':')
        .replace('Part – B: Total Practical Part :', ':')
        .replace('KBH : 1 - ', '')
        .replace('KBH : 2 - ', '')
      return (
        <>
          {categoryError === false ? (
            <></>
          ) : (
            <div className='alert-box-app'>
              <div className='alert-container'>
                <div className='title'>Choose your category</div>
                <div className='paragraph'>Please choose a category to buy this course.</div>
                <div className='button' onClick={() => setCategoryError(false)}>
                  Ok
                </div>
              </div>
            </div>
          )}
          <div className='app-course-detail'>
            <div className='course-video'>
              <div className='back-btn' style={{ display: 'none' }} onClick={() => window.history.back()}>
                <i className='fa fa-arrow-left' aria-hidden='true' />
              </div>
              <div className='video' style={{ backgroundColor: '#000' }} onClick={() => openModal(res.overview)}>
                {modalIsOpen === false ? (
                  <>
                    <div className='black-screen' />
                    <img
                      src={
                        res.courseOverviewThumb
                          ? res.courseOverviewThumb
                          : 'https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784.jpg'
                      }
                    />
                  </>
                ) : (
                  <VideoPlayer otp={videoOtp.otp} playbackInfo={videoOtp.playbackInfo} />
                )}
              </div>
            </div>
            <div className='app-course-detail-content'>
              <div className='navigation-app-course-detail'>
                <div className='tabs'>
                  <div
                    className={tab === 'Overview' ? 'tab active-tab' : 'tab'}
                    onClick={() => {
                      window.scrollTo(0, 0)
                      setTab('Overview')
                    }}
                  >
                    Overview
                    {tab === 'Overview' ? <div className='border'></div> : <></>}
                  </div>
                  <div
                    className={tab === 'Curriculum' ? 'tab active-tab' : 'tab'}
                    onClick={() => {
                      setTab('Curriculum')
                      window.scrollTo(0, 0)
                    }}
                  >
                    Curriculum
                    {tab === 'Curriculum' ? <div className='border'></div> : <></>}
                  </div>
                  <div
                    className={tab === 'Reviews' ? 'tab active-tab' : 'tab'}
                    onClick={() => {
                      setTab('Reviews')
                      window.scrollTo(0, 0)
                    }}
                  >
                    Features {tab === 'Reviews' ? <div className='border'></div> : <></>}
                  </div>
                  {!checkedBuyCourse && (
                    <div
                      className={tab === 'Pricing' ? 'tab active-tab' : 'tab'}
                      onClick={() => {
                        setTab('Pricing')
                        window.scrollTo(0, 0)
                      }}
                    >
                      Pricing {tab === 'Pricing' ? <div className='border'></div> : <></>}
                    </div>
                  )}
                </div>
              </div>

              {tab === 'Overview' ? (
                <>
                  <div className='app-course-title' style={{ color: 'orangered', fontWeight: 'bolder' }}>
                    {res.nameOfCourse}
                  </div>
                  <div className='app-course-index-contents' style={{ textAlign: 'center', lineHeight: '25px' }}>
                    <ShowMyChapters id={res._id} /> Chapters | <ShowMyCoursePageLessons id={res._id} /> Lessons |
                    {/* <ShowMyCoursePageQuiz id={res._id} /> */} {res.quizzes} Quiz
                    <br /> {res.TotalDuration}
                    Total Duration | E- Notes
                  </div>

                  <div className='app-line' />
                  <div className='' style={{ padding: '10px 1rem' }}>
                    {/* <div className='title' style={{ display: 'flex', justifyContent: 'center' }}>
                    <b>Overview</b>
                  </div> */}
                    <div className='contents'>
                      <DangerousHTML html={res.description} />
                    </div>
                  </div>
                  <div className='download-app-curriculum'>
                    <a
                      href={res.courseCurriculum}
                      target='__blank'
                      className='title'
                      style={{ fontSize: '20px', padding: '10px 8px' }}
                    >
                      <i className='fa fa-download' style={{ color: 'orangered' }} aria-hidden='true'></i> Download
                      Curriculum
                    </a>
                  </div>

                  <div className='app-tutor'>
                    <div className='instructor'>
                      <div className='image'>
                        <img src='/drkrutikshah.png' />
                      </div>
                      <div className='contents'>
                        <div className='title'>
                          <span style={{ color: 'orangered' }}>
                            Dr. Krutik Shah <br />
                          </span>
                        </div>
                        <div className='sub-title'>
                          {' '}
                          <span style={{ color: 'orangered' }}>Mentor</span>
                        </div>

                        <div className='author-bio' style={{ marginTop: '10px' }}>
                          Dr. Krutik Shah apart from receiving many national and international honours in his
                          homeopathic career, believes himself to be the best being known as a “Proud Child of Dr.
                          Samuel Hahnemann.” His life and work is dedicated to the promote teachings of Dr. Samuel
                          Hahnemann in a very religious way. He always believes “Original Homeopathy is Simple
                          Homeopathy.”
                        </div>
                      </div>
                    </div>
                    <div className='instructor'>
                      <div className='image'>
                        <img src={res.tutorImg} />
                      </div>
                      <div className='contents'>
                        <div className='title'>
                          <span style={{ color: 'orangered' }}> {res.tutorName}</span>
                        </div>
                        <div className='sub-title'>
                          <span style={{ color: 'orangered' }}> Coach</span>
                        </div>
                        <div className='author-bio' style={{ marginTop: '10px' }}>
                          {res.tutorBio}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='app-course-title' style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='title' style={{ fontSize: '1.3rem' }}>
                      Related Courses
                    </div>
                    <GridComponent
                      web={true}
                      name={res.nameOfCourse}
                      url='/shahlearnings/m/kbh'
                      title='Kaun Banega Homeopath'
                      category={res.category}
                      rating={res.rating}
                    />
                  </div>
                </>
              ) : tab === 'Curriculum' ? (
                <>
                  <div style={{ height: '100%' }}>
                    <AppChapterDropdown
                      tab={tab}
                      slug={slug}
                      checkedBuyCourse={checkedBuyCourse}
                      courseId={res._id}
                      myChapters={<ShowMyChapters id={res._id} />}
                      myLessons={<ShowMyCoursePageLessons id={res._id} />}
                      totalTime={res.TotalDuration}
                    />
                  </div>

                  {/* {checkedBuyCourse === false && (
                  <div className='app-course-buy-button'>
                    <div
                      className='btn'
                      onClick={() =>
                        PayButton(
                          discountPrice === null ? res.SellingPrice : discountPrice?.data?.INRPrice,
                          discountPrice === null ? res.DollarSellingPrice : discountPrice?.data?.DollarPrice,
                          discountPrice === null ? res.HomeopathicPrice : discountPrice?.data?.HomeopathicPrice,
                          res.nameOfCourse
                        )
                      }
                    >
                      Buy this Course
                    </div>
                    <div style={{ fontSize: '12px', textAlign: 'center', color: 'orangered', marginTop: '5px' }}>
                      No Cost EMI options available
                    </div>
                    <div style={{ fontSize: '12px', textAlign: 'center', color: 'orangered', marginTop: '5px' }}>
                      All the course prices are inclusive of taxes.
                    </div>
                  </div>
                )} */}
                </>
              ) : tab === 'Reviews' ? (
                <div style={{ marginTop: allChapter.length === 0 ? '' : '5rem' }}>
                  {allChapter.length === 0 && (
                    <div className='course-features-section' style={{ padding: '20px', paddingTop: '60px' }}>
                      <div className='feature'>
                        <div className='question'>
                          <b>Chapters</b>
                        </div>
                        <div className='answer'>
                          {' '}
                          <ShowMyChapters id={res._id} />
                        </div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>Lessons</b>
                        </div>
                        <div className='answer'>
                          {' '}
                          <ShowMyCoursePageLessons id={res._id} />
                        </div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>Clinical Quiz</b>
                        </div>
                        <div className='answer'>{res.quizzes}</div>
                      </div>
                      <hr />

                      {res.clinicalPoints !== 'No' && (
                        <div className='feature'>
                          <div className='question'>
                            <b>Summary of Clinical Points to learn</b>
                          </div>
                          <div className='answer'>{res.clinicalPoints}</div>
                        </div>
                      )}
                      {res.clinicalPoints !== 'No' && <hr />}

                      <div className='feature'>
                        <div className='question'>
                          <b>Course Certification</b>
                        </div>
                        <div className='answer'>Fellowship</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>Final Exam</b>
                        </div>
                        <div
                          className='answer'
                          style={{ cursor: 'pointer', color: 'orangered' }}
                          onClick={() => (window.location.href = '/faqs#1415')}
                        >
                          {' '}
                          FAQ No.14 & 15{' '}
                        </div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>Badge of Honour</b>
                        </div>
                        <div className='answer'> {res.BadgeHonor}</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>Live with Dr. Krutik Shah</b>{' '}
                        </div>
                        <div className='answer'>{res.liveShah}</div>
                      </div>
                      <hr />
                      <div className='feature'>
                        <div className='question'>
                          <b> Buy the Course Chapterwise</b>
                        </div>
                        <div className='answer'>{res.WatchCourse}</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          {' '}
                          <b>Course Access</b>
                        </div>
                        <div className='answer'> {res.CourseAccess}</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          {' '}
                          <b>Download Course Notes</b>
                        </div>
                        <div className='answer'>{res.notes}</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>
                            Chat using{' '}
                            <b
                              onClick={() => {
                                var userAgent = navigator.userAgent || navigator.vendor || window.opera

                                // Windows Phone must come first because its UA also contains "Android"
                                if (/windows phone/i.test(userAgent)) {
                                  window.open('https://play.google.com/store/apps/details?id=com.shahapp', '_blank')
                                  return
                                }

                                if (/android/i.test(userAgent)) {
                                  window.open('https://play.google.com/store/apps/details?id=com.shahapp', '_blank')
                                  return
                                }

                                // iOS detection from: http://stackoverflow.com/a/9039885/177710
                                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                                  window.open('https://apps.apple.com/in/app/shah-courses/id6470359344', '_blank')

                                  return
                                }
                              }}
                              style={{ color: 'orangered' }}
                            >
                              SHAH Courses
                            </b>{' '}
                            App
                          </b>
                        </div>
                        <div className='answer'>{res.ChatwithCoach}</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          {' '}
                          <b>Total Duration</b>
                        </div>
                        <div className='answer'>{res.TotalDuration}</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>Internal Recorded Webinar</b>{' '}
                        </div>
                        <div className='answer'>Yes</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>Rewards on early completion</b>{' '}
                        </div>
                        <div className='answer'>Yes</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>Language</b>{' '}
                        </div>
                        <div className='answer'>{res.Language}</div>
                      </div>
                      <hr />

                      <div className='feature'>
                        <div className='question'>
                          <b>Skill</b>{' '}
                        </div>
                        <div className='answer'>{res.SkillLevel}</div>
                      </div>
                      <hr />
                    </div>
                  )}
                  {referralID !== null && (
                    <div className='referral-code-main-div'>
                      <div className='text-div'>
                        <RWebShare
                          data={{
                            text: 'Yes I am proud to be a Homeopath',
                            url: `https://www.samuelhahnemann.org/signup?referral_code=${referralID}`,
                            title: 'samuelhahnemann'
                          }}
                          onClick={() => console.log('shared successfully!')}
                        >
                          <button className='share-code'>Share this course & Win</button>
                        </RWebShare>
                        <span className='text'>Refer a course to your friends and get 15% in your wallet.</span>
                      </div>
                    </div>
                  )}
                  <div className='type-form' style={{ backgroundColor: 'white', padding: '1rem' }}>
                    {/* <div
                    className='top-section'
                    style={{ fontSize: '14px', textAlign: 'center', color: 'orangered', textDecoration: 'underline' }}
                  >
                    Save 60% on Course Fees for a Group of 15 Students.
                  </div> */}
                    <TypoForm
                      type='course'
                      title='Save 60% on Course Fees for a Group of 15 Students'
                      secondTitle='Yes! We want to get a Group Access'
                    />
                    {/* <Widget id='IoScLGOg' data-tf-widget='IoScLGOg' style={{ width: '100%', height: '460px' }} /> */}
                  </div>
                  {allChapter.length === 0 && (
                    <div style={{ padding: '20px' }}>
                      <div className='shah-benefits'>
                        <h2>Benefits</h2>
                        <ul>
                          <div style={{ display: 'flex', alignItems: 'start' }}>
                            <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                            <li>Buy any Chapter without buying the full Organon Course!</li>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'start' }}>
                            <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                            <li>
                              Chat with mentor and coach using <b style={{ color: 'orangered' }}>SHAH Courses</b> App to
                              solve your doubts instantly.
                            </li>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'start' }}>
                            <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                            <li style={{ color: 'orangered' }}>
                              "Master Mind Call LIVE with Dr. Krutik Shah every fortnightly."{' '}
                            </li>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'start' }}>
                            <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                            <li>Free access to Internal Recorded Webinars of the same group of courses.</li>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'start' }}>
                            <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>

                            <li>Get Rewards on early Course Completion.</li>
                          </div>

                          <div style={{ display: 'flex', alignItems: 'start' }}>
                            <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>
                            <li>
                              You will receive course completion Certificate and Badge of Honour by courier at your
                              doorstep worldwide.
                            </li>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'start' }}>
                            <span style={{ marginTop: '10px', marginRight: '5px', color: 'orangered' }}>✓</span>
                            <li>100% Money Return in 5 days. No Question Asked.</li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  )}
                  {/* <div style={{ height: '100%' }}>
                <div className='app-course-title app-index-review'>
                  <div className='review-card'>
                    <div className='review-card-container'>
                      <div className='top-row'>
                        <div className='image'></div>
                        <div className='author'>
                          <div className='author-name'>Danish</div>
                          <div className='author-category'>Student</div>
                        </div>
                        <div className='rating'>
                          5<i className='fa fa-star' aria-hidden='true'></i>
                        </div>
                      </div>
                      <div className='bottom'>
                        <p>
                          One advanced diverted domestic repeated bringing you old. Possible procured her trifling
                          laughter thoughts property she met way.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                </div>
              ) : tab === 'Pricing' ? (
                <div style={{ height: '100%', marginBottom: !checkedBuyCourse ? '30%' : '0' }}>
                  {!checkedBuyCourse && (
                    <div
                      className='option-section'
                      style={{ margin: '4rem 0.8rem 0rem 0.8rem', textAlign: '-webkit-center' }}
                    >
                      <div
                        style={{
                          color: 'orangered',
                          fontWeight: 'bolder',
                          textAlign: 'center',
                          border: '2px solid orangered',
                          padding: '10px 5px',
                          margin: '15px 0',
                          width: '300px'
                        }}
                      >
                        {'Please Click on your category'.toUpperCase()}
                      </div>
                      <select
                        className='select-course-contain'
                        onChange={e => {
                          setSelectedCourse(e.target.value)
                          setAllChapter([])
                          setSelectedChapter(null)
                          setSelectChapterData(null)
                          setDiscountPrice(null)
                          setCoinValue(null)
                          setCouponCode(null)
                        }}
                        value={selectedCourse}
                        style={{
                          fontWeight: 'bolder',
                          backgroundColor: 'orangered',
                          color: 'white',
                          textAlign: 'center',
                          fontSize: '17px'
                        }}
                      >
                        <option value='full_course'>BUY FULL COURSE</option>
                        <option value='chapter_vise'>BUY THE CHAPTER</option>
                      </select>
                    </div>
                  )}
                  {selectedCourse === 'chapter_vise' && (
                    <div
                      className='option-section'
                      style={{ margin: '0 0.8rem', overflow: 'auto', padding: '0.5rem 0' }}
                    >
                      <select
                        onChange={e => {
                          setSelectedChapter(e.target.value)
                          selectChapterOnChange(e.target.value)
                        }}
                        value={selectedChapter}
                        style={{ fontWeight: 'bolder', color: 'orangered', padding: '0.5rem 0' }}
                      >
                        <option value='' key=''>
                          SELECT A CHAPTER
                        </option>
                        {allChapter.map(i => {
                          return (
                            <option value={i._id} key={i._id}>
                              {i.chapterName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  )}
                  {!onChangeChapterViseData && (
                    <div className='pricing' style={{ display: 'flex', paddingBottom: '2rem' }}>
                      {currentUserInformationParse ? (
                        currentUserInformationParse?.student_select === 'indian_students' &&
                        !checkedBuyCourse &&
                        selectedCourse === 'full_course' ? (
                          <div
                            className={
                              category === 'error'
                                ? 'price-box error-pricing'
                                : category === 'Indian Students Only'
                                ? 'price-box active-pricing'
                                : 'price-box'
                            }
                            onClick={() => setCategory('Indian Students Only')}
                          >
                            <div className='title' style={{ fontSize: '14px' }}>
                              INDIAN STUDENTS
                              <br />
                              (B.H.M.S. & M.D.)
                            </div>
                            <div className='price-tabel'>
                              <div className='actual-price'>
                                ₹{selectChapterData ? selectChapterData.indian_student_selling_price : res.SellingPrice}
                                /-
                              </div>
                              <div className='market-price'>
                                Regular Price: ₹
                                {selectChapterData ? selectChapterData.indian_student_regular_price : res.RegularPrice}
                                /-
                              </div>
                              <span style={{ fontSize: '12px', textAlign: 'center' }}>
                                (Promoting Make in India & Digital India Programs of Government of India)
                              </span>
                            </div>
                          </div>
                        ) : (
                          currentUserInformationParse?.student_select === 'indian_students' &&
                          !checkedBuyCourse && (
                            <div
                              className={
                                category === 'error'
                                  ? 'price-box error-pricing'
                                  : category === 'Indian Students Only'
                                  ? 'price-box active-pricing'
                                  : 'price-box'
                              }
                              onClick={() => setCategory('Indian Students Only')}
                            >
                              <div className='title' style={{ fontSize: '10px' }}>
                                INDIAN <br /> STUDENTS
                                <br />
                                (B.H.M.S. & M.D.)
                              </div>
                              <div className='price-tabel'>
                                <div className='actual-price'>
                                  ₹{selectChapterData ? selectChapterData.indian_student_selling_price : '-'}
                                  /-
                                </div>
                                <div className='market-price'>
                                  ₹{selectChapterData ? selectChapterData.indian_student_regular_price : '-'}
                                  /-
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : selectedCourse === 'full_course' && selectedOption === 'BHARAT' ? (
                        <div
                          className={
                            category === 'error'
                              ? 'price-box error-pricing'
                              : category === 'Indian Students Only'
                              ? 'price-box active-pricing'
                              : 'price-box'
                          }
                          onClick={() => setCategory('Indian Students Only')}
                        >
                          <div className='title' style={{ fontSize: '10px' }}>
                            INDIAN <br /> STUDENTS
                            <br />
                            (B.H.M.S. & M.D.)
                          </div>
                          <div className='price-tabel'>
                            <div className='actual-price'>
                              ₹{selectChapterData ? selectChapterData.indian_student_selling_price : res.SellingPrice}
                              /-
                            </div>
                            <div className='market-price'>
                              ₹{selectChapterData ? selectChapterData.indian_student_regular_price : res.RegularPrice}
                              /-
                            </div>
                          </div>
                        </div>
                      ) : selectedOption === 'BHARAT' && selectedCourse === 'full_course' && selectedCourse == null ? (
                        <div
                          className={
                            category === 'error'
                              ? 'price-box error-pricing'
                              : category === 'Indian Students Only'
                              ? 'price-box active-pricing'
                              : 'price-box'
                          }
                          onClick={() => setCategory('Indian Students Only')}
                        >
                          <div className='title' style={{ fontSize: '10px' }}>
                            INDIAN <br /> STUDENTS
                            <br />
                            AND HOMEOPATHS
                          </div>
                          <div className='price-tabel'>
                            <div className='actual-price'>
                              ₹{selectChapterData ? selectChapterData.indian_student_selling_price : res.SellingPrice}
                              /-
                            </div>
                            <div className='market-price'>
                              ₹{selectChapterData ? selectChapterData.indian_student_regular_price : res.RegularPrice}
                              /-
                            </div>
                          </div>
                        </div>
                      ) : (
                        selectedOption === 'BHARAT' && (
                          <div
                            className={
                              category === 'error'
                                ? 'price-box error-pricing'
                                : category === 'Indian Students Only'
                                ? 'price-box active-pricing'
                                : 'price-box'
                            }
                            onClick={() => setCategory('Indian Students Only')}
                          >
                            <div className='title' style={{ fontSize: '10px' }}>
                              INDIAN <br /> STUDENTS
                              <br />
                              AND HOMEOPATHS
                            </div>
                            <div className='price-tabel'>
                              <div className='actual-price'>
                                ₹{selectChapterData ? selectChapterData.indian_student_selling_price : '-'}
                                /-
                              </div>
                              <div className='market-price'>
                                ₹{selectChapterData ? selectChapterData.indian_student_regular_price : '-'}
                                /-
                              </div>
                            </div>
                          </div>
                        )
                      )}
                      {currentUserInformationParse ? (
                        currentUserInformationParse?.student_select === 'indian_homeopathic_practitioners' &&
                        selectedCourse === 'full_course' &&
                        !checkedBuyCourse ? (
                          <div
                            className={
                              category === 'error'
                                ? 'price-box error-pricing'
                                : category === 'Homeopathic Practitioner'
                                ? 'price-box active-pricing'
                                : 'price-box'
                            }
                            onClick={() => setCategory('Homeopathic Practitioner')}
                          >
                            <div className='title' style={{ fontSize: '15px' }}>
                              INDIAN HOMEOPATHIC PRACTITIONERS
                            </div>
                            <div className='price-tabel'>
                              <div className='actual-price'>
                                ₹
                                {selectChapterData
                                  ? selectChapterData.indian_student_selling_price
                                  : res.HomeopathicSellingPrice}
                                /-
                              </div>
                              <div className='market-price'>
                                Regular Price: ₹
                                {selectChapterData
                                  ? selectChapterData.indian_student_regular_price
                                  : res.HomeopathicRegularPrice}
                                /-
                              </div>
                              <span style={{ fontSize: '12px', textAlign: 'center' }}>
                                (Conforming Prime Minister of India's Vision - Vocal for Local and Local to Global)
                              </span>
                            </div>
                          </div>
                        ) : (
                          currentUserInformationParse?.student_select === 'indian_homeopathic_practitioners' &&
                          !checkedBuyCourse && (
                            <div
                              className={
                                category === 'error'
                                  ? 'price-box error-pricing'
                                  : category === 'Homeopathic Practitioner'
                                  ? 'price-box active-pricing'
                                  : 'price-box'
                              }
                              onClick={() => setCategory('Homeopathic Practitioner')}
                            >
                              <div className='title' style={{ fontSize: '10px' }}>
                                INDIAN <br />
                                HOMEOPATHIC <br /> PRACTITIONERS
                              </div>
                              <div className='price-tabel'>
                                <div className='actual-price'>
                                  ₹{selectChapterData ? selectChapterData.indian_student_selling_price : '-'}
                                  /-
                                </div>
                                <div className='market-price'>
                                  ₹{selectChapterData ? selectChapterData.indian_student_regular_price : '-'}
                                  /-
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : selectedCourse === 'full_course' && selectedOption === 'BHARAT' ? (
                        <div
                          className={
                            category === 'error'
                              ? 'price-box error-pricing'
                              : category === 'Homeopathic Practitioner'
                              ? 'price-box active-pricing'
                              : 'price-box'
                          }
                          onClick={() => setCategory('Homeopathic Practitioner')}
                        >
                          <div className='title' style={{ fontSize: '10px' }}>
                            INDIAN <br />
                            HOMEOPATHIC <br /> PRACTITIONERS
                          </div>
                          <div className='price-tabel'>
                            <div className='actual-price'>
                              ₹
                              {selectChapterData
                                ? selectChapterData.indian_student_selling_price
                                : res.HomeopathicSellingPrice}
                              /-
                            </div>
                            <div className='market-price'>
                              ₹
                              {selectChapterData
                                ? selectChapterData.indian_student_regular_price
                                : res.HomeopathicRegularPrice}
                              /-
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {currentUserInformationParse ? (
                        currentUserInformationParse?.student_select === 'international_homeopathic_students' &&
                        selectedCourse === 'full_course' &&
                        !checkedBuyCourse ? (
                          <div
                            className={
                              category === 'error'
                                ? 'price-box error-pricing'
                                : category === 'International Students'
                                ? 'price-box active-pricing'
                                : 'price-box'
                            }
                            onClick={() => setCategory('International Students')}
                          >
                            <div className='title' style={{ fontSize: '15px' }}>
                              INTERNATIONAL STUDENTS & HOMEOPATHS (Worldwide)
                            </div>
                            <div className='price-tabel'>
                              <div className='actual-price'>
                                $
                                {selectChapterData
                                  ? selectChapterData.international_selling_price
                                  : res.DollarSellingPrice}
                                /-
                              </div>
                              <div className='market-price'>
                                $ Regular Price:{' '}
                                {selectChapterData
                                  ? selectChapterData.international_regular_price
                                  : res.DollarRegularPrice}
                                /-
                              </div>
                              <span style={{ fontSize: '12px', textAlign: 'center' }}>
                                (Conforming Prime Minister of India's Vision - Vocal for Local and Local toGlobal)
                              </span>
                            </div>
                          </div>
                        ) : (
                          currentUserInformationParse?.student_select === 'international_homeopathic_students' &&
                          !checkedBuyCourse && (
                            <div
                              className={
                                category === 'error'
                                  ? 'price-box error-pricing'
                                  : category === 'International Students'
                                  ? 'price-box active-pricing'
                                  : 'price-box'
                              }
                              onClick={() => setCategory('International Students')}
                            >
                              <div className='title' style={{ fontSize: '10px' }}>
                                INTERNATIONAL STUDENTS & HOMEOPATHS
                              </div>
                              <div className='price-tabel'>
                                <div className='actual-price'>
                                  ${selectChapterData ? selectChapterData.international_selling_price : '-'}
                                  /-
                                </div>
                                <div className='market-price'>
                                  ${selectChapterData ? selectChapterData.international_regular_price : '-'}
                                  /-
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : selectedOption === 'INTERNATIONAL' && selectedCourse === 'full_course' ? (
                        <div
                          className={
                            category === 'error'
                              ? 'price-box error-pricing'
                              : category === 'International Students'
                              ? 'price-box active-pricing'
                              : 'price-box'
                          }
                          onClick={() => setCategory('International Students')}
                        >
                          <div className='title' style={{ fontSize: '10px' }}>
                            INTERNATIONAL STUDENTS & HOMEOPATHS
                          </div>
                          <div className='price-tabel'>
                            <div className='actual-price'>
                              $
                              {selectChapterData
                                ? selectChapterData.international_selling_price
                                : res.DollarSellingPrice}
                              /-
                            </div>
                            <div className='market-price'>
                              $
                              {selectChapterData
                                ? selectChapterData.international_regular_price
                                : res.DollarRegularPrice}
                              /-
                            </div>
                          </div>
                        </div>
                      ) : (
                        selectedOption === 'INTERNATIONAL' && (
                          <div
                            className={
                              category === 'error'
                                ? 'price-box error-pricing'
                                : category === 'International Students'
                                ? 'price-box active-pricing'
                                : 'price-box'
                            }
                            onClick={() => setCategory('International Students')}
                          >
                            <div className='title' style={{ fontSize: '10px' }}>
                              INTERNATIONAL STUDENTS & HOMEOPATHS
                            </div>
                            <div className='price-tabel'>
                              <div className='actual-price'>
                                ${selectChapterData ? selectChapterData.international_selling_price : '-'}
                                /-
                              </div>
                              <div className='market-price'>
                                ${selectChapterData ? selectChapterData.international_regular_price : '-'}
                                /-
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {selectedCourse !== 'chapter_vise' && selectedCourse !== null && !checkedBuyCourse && (
                    <>
                      <div
                        style={{
                          padding: '20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <input
                          type='text'
                          placeholder='Apply "SHAH30"'
                          onChange={e => setCouponCode(e.target.value)}
                          style={{ border: '1px solid orangered', padding: '14px', width: '40%', fontSize: '16px' }}
                        />
                        <Button
                          variant='contained'
                          onClick={() => {
                            // if (mongoId) {
                            DiscountApi()
                            // } else {
                            //   handleOpen()
                            // }
                          }}
                          style={{ color: 'white', backgroundColor: 'orangered', margin: 'auto', padding: '11px' }}
                        >
                          Check Coupon
                        </Button>
                      </div>

                      {discountPrice !== null && discountPrice?.data?.isValidCoin === false && (
                        <span style={{ color: 'orangered' }}>{'Not valid coin'}</span>
                      )}
                      {currentUserInformationParse?.coin ? (
                        <>
                          <p
                            style={{
                              color: 'orangered',
                              border: '1px solid orangered',
                              padding: '0.5rem 1rem',
                              margin: '1rem'
                            }}
                          >
                            Your Coin : {currentUserInformationParse?.coin}
                          </p>
                          <div
                            className='option-section'
                            style={{
                              padding: '20px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <input
                              type='text'
                              placeholder='Use Your Coin'
                              value={coinValue}
                              maxLength='2'
                              onChange={e => setCoinValue(e.target.value)}
                              onInput={e => {
                                if (currentUserInformationParse?.coin) {
                                  if (Number(e.target.value) <= currentUserInformationParse?.coin) {
                                    e.target.value = Number(e.target.value)
                                  } else {
                                    e.target.value = currentUserInformationParse?.coin
                                  }
                                }
                              }}
                              style={{
                                border: '1px solid orangered',
                                padding: '14px',
                                fontSize: '16px'
                              }}
                            />
                            <Button
                              variant='contained'
                              onClick={() => checkPrice()}
                              style={{ color: 'white', backgroundColor: 'orangered', margin: 'auto', padding: '11px' }}
                            >
                              Check Price
                            </Button>
                          </div>
                        </>
                      ) : null}
                      {discountPrice !== null && discountPrice?.data?.isValidCoin === false && (
                        <span style={{ color: 'orangered' }}>{'Not valid Coin'}</span>
                      )}
                    </>
                  )}
                  {discountPrice !== null && category && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {discountPrice.message !== 'Not valid coupon' && (
                        <div className='pricing'>
                          <div className={'price-box'}>
                            <div className='title'>Coupon Discount</div>
                            <div className='price-tabel'>
                              <div className='actual-price'>
                                {' '}
                                {currentUserInformationParse?.student_select === 'indian_students' ||
                                category === 'Indian Students Only'
                                  ? '₹'
                                  : currentUserInformationParse?.student_select ===
                                      'indian_homeopathic_practitioners' || category === 'Homeopathic Practitioner'
                                  ? '₹'
                                  : '$'}
                                {currentUserInformationParse?.student_select === 'indian_students' ||
                                category === 'Indian Students Only'
                                  ? discountPrice?.data?.INRPrice
                                  : currentUserInformationParse?.student_select ===
                                      'indian_homeopathic_practitioners' || category === 'Homeopathic Practitioner'
                                  ? discountPrice?.data?.HomeopathicPrice
                                  : discountPrice?.data?.DollarPrice}
                                /-
                              </div>
                              <div>
                                DiscountPrice:{' '}
                                {currentUserInformationParse?.student_select === 'indian_students'
                                  ? '₹'
                                  : currentUserInformationParse?.student_select === 'indian_homeopathic_practitioners'
                                  ? '₹'
                                  : '$'}
                                {currentUserInformationParse?.student_select === 'indian_students'
                                  ? (res.SellingPrice - Number(discountPrice?.data?.INRPrice)).toFixed(1)
                                  : currentUserInformationParse?.student_select === 'indian_homeopathic_practitioners'
                                  ? (
                                      res.HomeopathicSellingPrice - Number(discountPrice?.data?.HomeopathicPrice)
                                    ).toFixed(1)
                                  : (res.DollarSellingPrice - Number(discountPrice?.data?.DollarPrice)).toFixed(1)}
                                /-
                              </div>
                              <div className='market-price'>
                                CoursePrice:{' '}
                                {currentUserInformationParse?.student_select === 'indian_students'
                                  ? '₹'
                                  : currentUserInformationParse?.student_select === 'indian_homeopathic_practitioners'
                                  ? '₹'
                                  : '$'}
                                {currentUserInformationParse?.student_select === 'indian_students'
                                  ? res.SellingPrice
                                  : currentUserInformationParse?.student_select === 'indian_homeopathic_practitioners'
                                  ? res.HomeopathicSellingPrice
                                  : res.DollarSellingPrice}
                                /-
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {discountPrice !== null && discountPrice.message === 'Not valid coupon' && (
                        <span style={{ marginTop: '10px', color: 'orangered' }}>{discountPrice.message}</span>
                      )}
                    </div>
                  )}
                  {(checkedBuyCourse === null || checkedBuyCourse === false) && !onChangeChapterViseData && (
                    <div className='app-course-buy-button'>
                      <div
                        className='btn'
                        onClick={() =>
                          PayButton(
                            discountPrice === null
                              ? selectChapterData
                                ? Number(selectChapterData?.indian_student_selling_price)
                                : res.SellingPrice
                              : discountPrice?.data?.INRPrice,
                            discountPrice === null
                              ? selectChapterData
                                ? Number(selectChapterData?.international_selling_price)
                                : res.DollarSellingPrice
                              : discountPrice?.data?.DollarPrice,
                            discountPrice === null
                              ? selectChapterData
                                ? Number(selectChapterData?.indian_homeopathic_selling_price)
                                : res.HomeopathicSellingPrice
                              : discountPrice?.data?.HomeopathicPrice,
                            res.nameOfCourse,
                            res,
                            res?.slug,
                            res?.Img,
                            window.location.href
                          )
                        }
                      >
                        {selectedCourse !== 'chapter_vise' ? ' BUY THIS COURSE' : 'BUY THE CHAPTER'}
                      </div>
                      <div style={{ fontSize: '12px', textAlign: 'center', color: 'orangered', marginTop: '5px' }}>
                        No Cost EMI options available upon full course purchase only.
                      </div>
                      <div style={{ fontSize: '12px', textAlign: 'center', color: 'orangered', marginTop: '5px' }}>
                        All the course prices are inclusive of taxes.
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Modal open={open} onClose={handleClose}>
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,

                border: '2px solid #fff',

                borderRadius: 10,
                padding: '1rem',
                backgroundColor: 'white',
                zIndex: 9999
              }}
            >
              <Typography
                id='modal-modal-title'
                variant='h6'
                component='h6'
                style={{ fontSize: '17px', textAlign: 'center' }}
              >
                Login is a mandatory to avail maximum discount in all the Organon Courses offered by SHAH
              </Typography>
              <div style={{ display: 'flex', gap: 15, justifyContent: 'center', marginTop: '15px' }}>
                <Button
                  variant='contained'
                  onClick={() => {
                    window.location.href = '/login'
                  }}
                >
                  <b>Login</b>
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  style={{ backgroundColor: 'orangered', color: 'white' }}
                  onClick={handleClose}
                >
                  <b>Cancel</b>
                </Button>
              </div>
            </Box>
          </Modal>
        </>
      )
    })
  }
}
