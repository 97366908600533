/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Suspense, lazy } from 'react'

import '../styles/Settings.scss'
import home from '../img/home .png'
import setting from '../img/settings.png'
import life from '../img/lifesaver.png'
import catalog from '../img/com.png'
import logout from '../img/logout.png'
import Chat from '../img/chat3.png'
// import alert from '../img/alert.png'
import { Helmet } from 'react-helmet'
import SplashScreen from './SplashScreen'
import axios from 'axios'

// import UserSettingsName from '../components/UserSettingsName'
const UserSettingsName = lazy(() => import('../components/UserSettingsName'))
// import PasswordChange from '../components/PasswordChange'
const PasswordChange = lazy(() => import('../components/PasswordChange'))
// import CoursePurchased from '../components/CoursePurchased'
const CoursePurchased = lazy(() => import('../components/CoursePurchased'))

export default function PurchasedCourse() {
  const [userData] = useState([])
  const mongoId = localStorage.getItem('mongoId')
  const token = localStorage.getItem('accessToken')
  // const [certificate, setCertificate] = useState('Download Certificate')
  // const [url, setURL] = useState('')
  // const id = localStorage.getItem('loginUser')

  const [Calender, setCalender] = useState(true)
  const [Today, setToday] = useState(false)
  const [Tomorrow, setTomorrow] = useState(false)
  const [timeInChapter, setTimeInChapter] = useState(null)

  // const [value, setValue] = useState(false)

  // const [name, setName] = useState('')
  // const [phone, setPhone] = useState('')
  const [type] = useState('')
  const [purchased, setPurchased] = useState(true)
  const [courseData, setCourseData] = useState([])
  const [block, setBlock] = useState('')
  let config = {
    headers: {
      Authorization: 'Bearer' + ' ' + token
    },
    params: {
      studentid: mongoId
    }
  }

  // console.log('courseData', courseData)

  useEffect(() => {
    getData()

    if (type === 'Reported') {
      localStorage.removeItem('loginUser')
      localStorage.removeItem('studentStore')
      localStorage.removeItem('mongoId')
      localStorage.removeItem('buyCourses')
      localStorage.removeItem('profileInformation')
      localStorage.removeItem('saveCurrentChat')
    }
  }, [type])

  function Result(c) {
    if (c === 'c') {
      setToday(false)
      setTomorrow(false)
      setCalender(true)
    } else if (c === 't') {
      setToday(true)
      setTomorrow(false)
      setCalender(false)
    } else if (c === 'tom') {
      setToday(false)
      setTomorrow(true)
      setCalender(false)
    }
  }

  async function getData() {
    // const id = localStorage.getItem('loginUser') + ''
    if (mongoId) {
      try {
        const response = await axios.get('/api/order/buy-course-list', config)

        const buyCoursesData = response.data.data

        const filterTimeOfChapter = buyCoursesData.filter(i => i.chaptername)

        const getChapter = filterTimeOfChapter.flatMap(i => i.chapter)
        const getLesson = getChapter.map(i => i.lessons)
        const oneArray = [...getLesson].flat()
        const getTime = oneArray.map(i => i.lessonTime)

        // Convert each time to seconds and sum them up
        const totalSeconds = getTime.reduce((acc, time) => {
          const timeParts = time.split(/[:.]/).map(Number)
          const minutes = timeParts[0] || 0
          const seconds = timeParts[1] || 0
          return acc + minutes * 60 + seconds
        }, 0)

        // Calculate hours, minutes, and seconds from total seconds
        const hours = Math.floor(totalSeconds / 3600)
        const minutes = Math.floor((totalSeconds % 3600) / 60)
        const seconds = totalSeconds % 60
        setTimeInChapter({ hours, minutes, seconds })

        setCourseData(buyCoursesData)
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem('loginUser')
          localStorage.removeItem('studentStore')
          localStorage.removeItem('profileInformation')
          localStorage.removeItem('buyCourses')
          localStorage.removeItem('userId')
          localStorage.removeItem('mongoId')
          localStorage.removeItem('referral_code')
          localStorage.removeItem('userData')
          localStorage.removeItem('accessToken')
          localStorage.removeItem('saveCurrentChat')
          localStorage.removeItem('quiz')
          window.location.href = '/'
        }
        console.log('Error fetching buy course list:', error)
      }
    }
  }

  useEffect(() => {
    getUserData()
  }, [])

  async function getUserData() {
    const userInformationData = localStorage.getItem('profileInformation')

    const parseSlugBuyData = JSON.parse(userInformationData)

    if (parseSlugBuyData?.block === 'blocked') {
      setBlock('blocked')
    } else {
      setBlock('unblocked')
    }
  }

  return (
    <>
      <Helmet>
        <title>E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy - Purchased Course</title>
        <meta
          name='description'
          content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
        />
        {/* Add more meta tags as needed */}
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            name: 'E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy',
            url: 'https://www.samuelhahnemann.org/'
            // Add more structured data properties
          })}
        </script>
      </Helmet>
      <Suspense fallback={<SplashScreen />}>
        {purchased === false ? (
          <>
            <div className='profile-view'>
              <div className='full-setting'>
                <div className='nav1'>Settings</div>
                <div className='side'>
                  <ul>
                    <li onClick={() => (window.location.href = '/')}>
                      {' '}
                      <img src={home} />
                      Home
                    </li>
                    <li onClick={() => (window.location.href = '/purchased-course')}>
                      <img src={life} />
                      Purchased Courses
                    </li>
                    <li onClick={() => (window.location.href = '/browse-course')}>
                      <img src={catalog} />
                      Browse Course
                    </li>
                    <li onClick={() => (window.location.href = '/profile')}>
                      <img src={Chat} />
                      Chat
                    </li>
                    <li onClick={() => (window.location.href = '/profile')}>
                      <img src={setting} />
                      Settings
                    </li>

                    <li
                      onClick={() => {
                        localStorage.removeItem('loginUser')
                        localStorage.removeItem('studentStore')
                        localStorage.removeItem('profileInformation')
                        localStorage.removeItem('buyCourses')
                        localStorage.removeItem('userId')
                        localStorage.removeItem('mongoId')
                        localStorage.removeItem('referral_code')
                        localStorage.removeItem('quiz')
                        window.location.href = '/'
                      }}
                    >
                      <img src={logout} />
                      Logout
                    </li>
                  </ul>
                </div>

                <div className='both'>
                  <div className='in-side'>
                    <h4>Account</h4>

                    <div className='list'>
                      <div onClick={() => Result('c')} className='click-btn'>
                        Personal Information
                      </div>
                      <div onClick={() => Result('t')} className='click-btn'>
                        Password
                      </div>
                      <div onClick={() => Result('tom')} className='click-btn'>
                        Student Documents
                      </div>
                    </div>
                  </div>
                  <div className='in-box'>
                    <div className='nxt'>
                      {Calender === true ? (
                        <UserSettingsName userData={userData} />
                      ) : Today === true ? (
                        <PasswordChange userData={userData} />
                      ) : Tomorrow === true ? (
                        <div className='one-three'>
                          <h2
                            style={{
                              color: userData.verified === true ? 'green' : 'red'
                            }}
                          >
                            {userData.verified === true ? 'Documents verified' : 'Documents under verifying'}
                          </h2>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* {console.log('window.location.href', window.location.href.split('/')[3] === 'purchased-course')} */}
            <div className='profile-view'>
              <div className='full-setting'>
                <div className='nav1' style={{ paddingLeft: '110px' }}>
                  Purchased Course
                </div>
                <div className='side' style={{ paddingLeft: 23 }}>
                  <ul>
                    <li onClick={() => (window.location.href = '/')}>
                      {' '}
                      <img src={home} />
                      Home
                    </li>
                    <li
                      onClick={() => {
                        purchased === true ? setPurchased(true) : setPurchased(true)
                      }}
                      style={{ color: window?.location?.href?.split('/')[3] === 'purchased-course' ? 'orangered' : '' }}
                    >
                      <img src={life} />
                      Purchased Courses
                    </li>
                    <li onClick={() => (window.location.href = '/browse-course')}>
                      <img src={catalog} />
                      Browse Course
                    </li>
                    <li
                      onClick={() => {
                        window.location.href = '/chat'
                      }}
                      style={{ color: window?.location?.href?.split('/')[3] === 'chat' ? 'orangered' : '' }}
                    >
                      <img src={Chat} />
                      Chat
                    </li>
                    <li
                      onClick={() => {
                        window.location.href = '/profile'
                      }}
                      style={{ color: window?.location?.href?.split('/')[3] === 'profile' ? 'orangered' : '' }}
                    >
                      <img src={setting} />
                      Setting
                    </li>
                    <li
                      onClick={() => {
                        localStorage.removeItem('loginUser')
                        localStorage.removeItem('studentStore')
                        localStorage.removeItem('profileInformation')
                        localStorage.removeItem('buyCourses')
                        localStorage.removeItem('userId')
                        localStorage.removeItem('mongoId')
                        localStorage.removeItem('referral_code')
                        localStorage.removeItem('quiz')
                        window.location.href = '/'
                      }}
                    >
                      <img src={logout} />
                      Logout
                    </li>
                  </ul>
                </div>

                <>
                  <div className='both'>
                    {block === 'blocked' ? (
                      <>
                        <p>
                          Your account is blocked. please contact our support team ( support@samuelhahnemann.org ) to
                          unlock your account.
                        </p>
                      </>
                    ) : (
                      <>
                        <div className='purchased-course-card'>
                          {courseData?.map(res => {
                            return (
                              <React.Fragment key={res._id}>
                                <CoursePurchased
                                  value={res.coursename}
                                  chapterid={res.chapterid}
                                  id={res.courseid}
                                  chaptername={res.chaptername}
                                  chapter_thumbnail={res?.chapter_thumbnail || null}
                                  timeInChapter={timeInChapter}
                                />
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </Suspense>
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'E-Learning @ SHAH',
          headline: 'E-Learning on My Website',
          datePublished: '2020-08-29',
          description:
            'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
          image: {
            '@type': 'ImageObject',
            url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
            width: 800,
            height: 600
          },
          author: {
            '@type': 'Person',
            name: 'Dr.Krutik Shah'
          },
          publisher: {
            '@type': 'Organization',
            name: 'Dr.Krutik Shah',
            logo: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 200,
              height: 100
            }
          }
        })}
      </script>
    </>
  )
}
