/* eslint-disable no-unused-vars */
import React, { useState, useEffect, lazy, Suspense } from 'react'

import '../styles/coursepage.scss'

import useWindowDimesions from '../components/useWindowDimesions'

import Overview from '../components/courseOverview'

import DangerousHTML from 'react-dangerous-html'

const Footer = lazy(() => import('../components/Footer'))
import '../styles/coursepage.scss'
const Hero = lazy(() => import('../components/CourseHero'))
const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))

const ChapterDropdown = lazy(() => import('../components/ChapterDropdown'))
import axios from 'axios'
const RelatedCourses = lazy(() => import('../components/RelatedCourses'))

import { ShowMyChapters, ShowMyCoursePageLessons } from '../components/ShowMyChapters'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'

import MobileViewAppCoursePage from './ShahLearnings/MobileViewAppCoursePage'
const OverView = lazy(() => import('../components/OverView'))
const TypoForm = lazy(() => import('../components/typoForm'))

import { Helmet } from 'react-helmet'
import SplashScreen from './SplashScreen'

Modal.setAppElement('#root')

const TestCoursePage = () => {
  const { width } = useWindowDimesions()

  const [Data, setData] = useState([])

  const [checkedBuyCourse, setCheckedBuyCourse] = useState(null)
  const [overViewVideo, setOverViewVideo] = useState('')
  const [wait, setWait] = useState(true)

  // const [commentValue, setCommentValue] = useState('')
  let { slug } = useParams()

  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal(e, video) {
    localStorage.setItem('overview', video)
    setOverViewVideo(e)
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const mongoId = localStorage.getItem('mongoId')

  const token = localStorage.getItem('accessToken')
  let config = {
    headers: {
      Authorization: 'Bearer' + ' ' + token
    },
    params: {
      studentid: mongoId
    }
  }

  async function getData() {
    if (mongoId) {
      try {
        const response = await axios.get('/api/order/buy-course-list', config)
        const buyCoursesData = response.data.data

        const filterData = buyCoursesData.filter(i => i.slug === slug)

        let checkValue = false
        filterData?.map(item => {
          if (item?.courseid && item?.chapterid !== null) {
            checkValue = false
          } else {
            checkValue = true
          }
        }) || null
        setCheckedBuyCourse(checkValue)
      } catch (error) {
        console.log('Error fetching buy course list:', error)
      }
    }

    // const buyCourseSlugData = localStorage.getItem('buyCourses')
    // if (buyCourseSlugData) {
    //   const parseSlugBuyData = JSON.parse(buyCourseSlugData)
    //   let checkValue = parseSlugBuyData?.some(item => item.slug === slug)

    //   setCheckedBuyCourse(checkValue)
    // }
  }

  const callInitialFunction = async () => {
    await getData()
    await FetchData()
    setWait(false)
  }

  useEffect(() => {
    callInitialFunction()
  }, [])

  async function FetchData() {
    await axios
      .get(`/api/course/get-slug/${slug}?type=front-end-api`)
      .then(async res => {
        res?.data?.chapters?.sort((a, b) => a.chapterNumber - b.chapterNumber)
        if (mongoId) {
          try {
            const response = await axios.get('/api/order/buy-course-list', config)

            const buyCoursesData = response.data.data

            if (res?.data?.slug === 'Theory1') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Theory1.pdf'
            }

            if (res?.data?.slug === 'Practical1') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Practical1.pdf'
            }
            if (res?.data?.slug === 'Practical2') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Practical2.pdf'
            }
            if (res?.data?.slug === 'Practical3') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Practical3.pdf'
            }
            if (res?.data?.slug === 'Totalpractical') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Totalpractical.pdf'
            }
            if (res?.data?.slug === 'Fulldigital') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Fulldigital.pdf'
            }
            if (res?.data?.slug === 'Basic') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Basic.pdf'
            }
            if (res?.data?.slug === 'Complete') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Complete.pdf'
            }
            if (res?.data?.slug === 'Advanced') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Advanced.pdf'
            }
            if (res?.data?.slug === 'Prescription') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Prescription.pdf'
            }
            if (res?.data?.slug === 'Acute') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Acute.pdf'
            }
            if (res?.data?.slug === 'Total') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Total.pdf'
            }
            if (res?.data?.slug === 'Dangerousfuture') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Dangerousfuture.pdf'
            }
            if (res?.data?.slug === 'Femalehomeopathy') {
              res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Femalehomeopathy.pdf'
            }

            if (buyCoursesData) {
              const filterStoreData = buyCoursesData.filter(i => i.slug === slug)

              const hasKetanWithNullChapterId = filterStoreData.some(
                item => item.slug === slug && item.chapterid === null
              )

              if (hasKetanWithNullChapterId) {
                setData(res.data)
              } else {
                res.data.chapters.sort((a, b) => a.chapterNumber - b.chapterNumber)

                if (res.data._id === filterStoreData[0]?.courseid) {
                  res.data.chapter_id = filterStoreData[0].chapterid
                }

                setData(res.data)
              }
            }
          } catch (error) {
            if (error?.response?.status === 401) {
              localStorage.removeItem('loginUser')
              localStorage.removeItem('studentStore')
              localStorage.removeItem('profileInformation')
              localStorage.removeItem('buyCourses')
              localStorage.removeItem('userId')
              localStorage.removeItem('mongoId')
              localStorage.removeItem('referral_code')
              localStorage.removeItem('userData')
              localStorage.removeItem('accessToken')
              localStorage.removeItem('saveCurrentChat')
              localStorage.removeItem('quiz')
              window.location.href = '/'
            }
            console.log('buy-course-list error', error)
          }
        } else {
          if (res?.data?.slug === 'Theory1') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Theory1.pdf'
          }

          if (res?.data?.slug === 'Theory1') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Theory1.pdf'
          }
          if (res?.data?.slug === 'Practical1') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Practical1.pdf'
          }
          if (res?.data?.slug === 'Practical2') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Practical2.pdf'
          }
          if (res?.data?.slug === 'Practical3') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Practical3.pdf'
          }
          if (res?.data?.slug === 'Totalpractical') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Totalpractical.pdf'
          }
          if (res?.data?.slug === 'Fulldigital') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Fulldigital.pdf'
          }
          if (res?.data?.slug === 'Basic') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Basic.pdf'
          }
          if (res?.data?.slug === 'Complete') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Complete.pdf'
          }
          if (res?.data?.slug === 'Advanced') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Advanced.pdf'
          }
          if (res?.data?.slug === 'Prescription') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Prescription.pdf'
          }
          if (res?.data?.slug === 'Acute') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Acute.pdf'
          }
          if (res?.data?.slug === 'Total') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Total.pdf'
          }
          if (res?.data?.slug === 'Dangerousfuture') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Dangerousfuture.pdf'
          }
          if (res?.data?.slug === 'Femalehomeopathy') {
            res.data.courseCurriculum = process.env.REACT_APP_API_ENDPOINT + '/curriculum/Femalehomeopathy.pdf'
          }

          console.log(`🚀  ~ file: TestCoursePage.js:250 ~ FetchData ~ res:`, res)

          setData(res.data)
        }
      })

      .catch(e => console.log('course?type=front-end-api catch error', e))
  }

  if (wait) {
    return <SplashScreen />
  } else {
    if (width < 999) {
      return (
        <>
          <Helmet>
            <title>Organon with SHAH | Samuel Hahnemann Academy for Homeopathy - Course</title>
            <meta
              name='description'
              content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
            />
          </Helmet>
          <Suspense fallback={<SplashScreen />}>
            <div style={{ zIndex: '99999' }}></div>
            {modalIsOpen ? (
              <>
                <div className='blur-overview'>
                  <p onClick={closeModal}>
                    <i className='fa fa-times' aria-hidden='true'></i> Cancel
                  </p>
                  <div className='mpop-up-overview'>{/* <OverView src={overViewVideo} /> */}</div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div
              onClick={() => window.history.back()}
              className='back'
              style={{
                width: '100vw',
                padding: '0 15px',
                background: '#fff',
                borderBottom: '2px solid #4a494957',
                fontFamily: "'Jost',sans-serif",
                fontSize: '1.3rem',
                cursor: 'pointer',
                position: 'fixed',
                zIndex: '100'
              }}
            >
              {' '}
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    zIndex: '999999',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px',
                    paddingLeft: '0px'
                  }}
                >
                  <i
                    className='fa fa-arrow-left'
                    aria-hidden='true'
                    style={{ marginRight: '5px', marginTop: '4px', fontSize: '18px' }}
                  ></i>{' '}
                </div>
                {/* <marquee
                  behavior='scroll'
                  direction='left'
                  style={{ fontFamily: " 'Jost',sans-serif", color: 'orangered', fontSize: '14px', marginTop: '5px' }}
                >
                  USE CODE <b className='blink-hard'>SHAH30</b> To Avail <b className='blink-hard'>30%</b> Discount on
                  your courses on the occasion of Diwali and New Year Celebrations!
                </marquee> */}
                <marquee
                  behavior='scroll'
                  direction='left'
                  style={{ fontFamily: " 'Jost',sans-serif", color: 'orangered', fontSize: '14px', marginTop: '5px' }}
                >
                  It is the only e-learning program in the world giving exclusive education in the subject of Organon of
                  Medicine and Homeopathic Philosophy.
                </marquee>
              </div>
            </div>
            <div style={{ paddingTop: 32 }}>
              <MobileViewAppCoursePage slug={slug} />
            </div>
          </Suspense>
        </>
      )
    } else {
      return (
        <>
          <Helmet>
            <title>Organon with SHAH | Samuel Hahnemann Academy for Homeopathy - Course</title>
            <meta
              name='description'
              content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
            />
          </Helmet>
          <Suspense fallback={<SplashScreen />}>
            {modalIsOpen ? (
              <>
                <div className='blur-overview'>
                  <p onClick={closeModal}>
                    <i className='fa fa-times' aria-hidden='true'></i> Cancel
                  </p>
                  <div className='pop-up-overview'>
                    <OverView src={overViewVideo} />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className='desktop'>
              <DesktopNavBar />

              <Suspense fallback={<SplashScreen />}>
                <Hero title={Data?.nameOfCourse} img={Data?.banners} divide={Data?.divide} />

                <div className='main-course-detail-page'>
                  <Overview
                    onClick={() => openModal(Data?.overview, Data?.awsoverview)}
                    user={mongoId}
                    res={Data}
                    value='Course Certification'
                    subValue='Fellowship'
                    checkedBuyCourse={checkedBuyCourse}
                  />
                  <div className='course-details-page'>
                    <div className='Course Curriculum'>
                      <h3
                        style={{
                          marginLeft: '3%',
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: 20,
                          fontWeight: '600'
                        }}
                      >
                        Overview
                      </h3>

                      <div className='para-information overview'>
                        {/*  */}

                        <div className='combine' style={{ display: 'flex', flexDirection: 'column' }}>
                          <div className='info'>
                            <div className='content-source'>
                              <DangerousHTML html={Data?.description} />
                            </div>
                          </div>
                        </div>

                        {/*  */}
                      </div>
                    </div>
                    <h3
                      style={{
                        position: 'relative',
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: 20,
                        fontWeight: '600',
                        marginLeft: '2em',
                        marginTop: '2em',
                        width: 'calc(100% - 435px)'
                      }}
                    >
                      Course Curriculum
                      <span className='download-curriculum'>
                        {/* <a
                                style={{ color: 'orangered', textDecoration: 'none' }}
                                href={res.courseCurriculum}
                                target='_blank'
                                rel='noreferrer'
                                title='Download Curriculum'
                                aria-label='Download Curriculum'
                              >
                                <i className='fa fa-download' aria-hidden='true'></i> Download Curriculum
                              </a> */}
                        <div
                          style={{ color: 'orangered', textDecoration: 'none' }}
                          onClick={() => {
                            const newTab = window.open(Data?.courseCurriculum, '_blank')
                            if (newTab) {
                              newTab.name = 'Download Curriculum'

                              newTab.document.title = 'Download Curriculum'
                            } else {
                              alert('Your browser blocked opening a new tab. Please check your browser settings.')
                            }
                          }}
                        >
                          <i className='fa fa-download' aria-hidden='true'></i> Download Curriculum
                        </div>
                      </span>
                    </h3>
                    <div className='topics'>
                      <ChapterDropdown
                        slug={slug}
                        viewVideo={Data}
                        chapter_id={Data.chapter_id}
                        checkedBuyCourse={checkedBuyCourse}
                        onClick={() => openModal(Data.overview, Data.overview, Data.awsoverview)}
                        courseId={Data._id}
                        TotalDuration={Data.TotalDuration}
                        // myChapters={<ShowMyChapters id={Data._id} />}
                        // myLessons={<ShowMyCoursePageLessons id={Data._id} />}
                        totalTime={Data.TotalDuration}
                        {...Data}
                      />
                    </div>

                    <div className='teacher-section'>
                      <div className='krutikShah'>
                        <div className='author-reviewcontent'>
                          <div className='image-section'>
                            <div className='image-container'>
                              <img src='/drkrutikshah.png' />
                            </div>
                          </div>

                          <div className='reviewcontent'>
                            <div className='instructor'>
                              <span className='teacher'>Mentor</span>
                            </div>

                            <div className='author-data'>
                              <div className='name'>Dr. Krutik Shah</div>
                              <div className='author-bio'>
                                Dr. Krutik Shah apart from receiving many national and international honours in his
                                homeopathic career, believes himself to be the best, being known as a “Proud Child of
                                Dr. Samuel Hahnemann.” His life and work is dedicated to the promote teachings of Dr.
                                Samuel Hahnemann in a very religious way. He always believes “Original Homeopathy is
                                Simple Homeopathy.”
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className='FalguniHathila'
                        style={{ visibility: Data.tutorName.length === 0 ? 'hidden' : 'visible' }}
                      >
                        <div className='author-reviewcontent'>
                          <div className='image-section'>
                            <div className='image-container'>
                              <img src={Data.tutorImg} />
                            </div>
                          </div>
                          <div className='reviewcontent'>
                            <div className='instructor'>
                              <span className='teacher'>Coach</span>
                            </div>
                            <div className='author-data'>
                              <div className='name'>{Data.tutorName}</div>
                              <div className='author-bio'>{Data.tutorBio}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='group-save'>
                        <TypoForm
                          type='course'
                          title='Save 60% on Course Fees for a Group of 15 Students'
                          secondTitle='Yes! We want to get a Group Access'
                        />
                      </div>
                    </div>

                    <div className='group-save' style={{ display: 'none' }}>
                      <div className='box'>
                        <div className='top-section' style={{ color: 'orangered', textDecoration: 'underline' }}>
                          Save 60% on Course Fees for a Group of 15 Students.
                        </div>

                        <div className='group-access-link'>
                          <a
                            href='https://5ucgovoefff.typeform.com/to/IoScLGOg?typeform-source=samuelhahnemann.org'
                            target='_blank'
                            rel='noreferrer'
                          >
                            GET GROUP ACCESS NOW
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className='courses'>
                      <RelatedCourses name={Data.nameOfCourse} category={Data.category} style={'related'} />
                    </div>
                  </div>
                </div>
              </Suspense>

              <div style={{ marginTop: '15rem' }}>
                <Footer />
              </div>
            </div>
          </Suspense>
        </>
      )
    }
  }
}

export default TestCoursePage
