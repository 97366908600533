import React, { Suspense, lazy } from 'react'

import '../styles/nav.scss'

import useWindowDimesions from '../components/useWindowDimesions'

// import Footer from '../components/Footer'
const Footer = lazy(() => import('../components/Footer'))
// import Hero from '../components/Hero'
const Hero = lazy(() => import('../components/Hero'))
// import AccordianComponent from '../components/AccordianComponent'
const AccordianComponent = lazy(() => import('../components/AccordianComponent'))
// import AccordianComponentActive from '../components/AccordianComponentActive'
const AccordianComponentActive = lazy(() => import('../components/AccordianComponentActive'))
// import MobileNavBar from '../components/MobileNavBar'
const MobileNavBar = lazy(() => import('../components/MobileNavBar'))
// import DesktopNavBar from '../components/DesktopNavBar'
const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))
import { Helmet } from 'react-helmet'
import SplashScreen from './SplashScreen'

const Faqs = () => {
  // const [open, isOpen] = useState(false)
  const { width } = useWindowDimesions()
  // const pages =['Home', "About", "Courses", "News &  Media","Why Study at SHAH?"]

  if (width < 999) {
    return (
      <>
        <Helmet>
          <title>Organon with SHAH | Samuel Hahnemann Academy for Homeopathy - FAQ</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organon with SHAH',
              name: 'Organon with SHAH | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <MobileNavBar />

          <Hero title='FAQs' img='images/FAQ.jpg' />
          {/* <EmptyPage/> */}
          <div className='mob-faq'>
            <AccordianComponent
              title='1. What these courses are all about?'
              content={
                <>
                  It is the only e-learning program in the world giving exclusive education in the subject of Organon of
                  Medicine and Homeopathic Philosophy.
                  <br /> These vocational clinical courses are based on the highest educational standard following the
                  footprints of Dr. Samuel Hahnemann & Dr. Kent, taught by Proud Child of Dr. Samuel Hahnemann Dr.
                  Krutik Shah directly.
                </>
              }
            />
            <AccordianComponent
              title='2. How much will be the duration of each lecture?'
              content={
                <>
                  E-Learning @ SHAH has approximately 150 hours of lectures in the subject of Organon of Medicine in
                  multiple courses. More than 50 hours will be added in a month to come.
                  <br />
                  Each lecture has been made short into multiple small lectures to give you easy, attractive and
                  efficient learning in the easiest possible form; so that you can learn the best, even when you are
                  traveling or walking….!! <br />
                  All the lectures have undergone thorough audio and video treatment to give you the best experience
                  from whatever device you are using. However, it is advisable to use microphones for the best audio
                  experience from the lecture.
                  {/* <br />
                  <br />
                  <ul>
                    <li>
                      <u>Dr. Hahnemann’s Digital Organon of Medicine:</u>
                      <br />
                      <p>Each aphorism has been placed differently with or without its footnote(s).</p>
                      <br />
                      <p style={{ marginTop: -26, paddingRight: 9 }}>
                        <i>
                          Therefore, each lecture on each aphorism has been made separately, ranging from 5 minutes to
                          35 minutes approximately to give attractive and easy learning so that you can learn the best,
                          even when you are traveling or walking….!!
                        </i>
                      </p>
                    </li>

                    <li>
                      <u>Organon - Access to Success Course Series:</u>
                      <br />
                      <p>
                        <i>
                          Each lecture has been made short into multiple small lectures to give efficient but intense
                          learning in the easiest possible form.
                        </i>
                      </p>
                    </li>
                  </ul> */}
                </>
              }
            />

            <AccordianComponent
              title='3. What are the duration of courses?'
              content={
                <>
                  Different courses have different tenures ranging from one month to one year. <br />
                  All the courses can be done over & above professional hours. <br />
                  If you want to break the learning due to some reasons, a mentor assigned to you for the course should
                  be requested, and you may resume it whenever you want.
                </>
              }
            />

            <AccordianComponent
              title='4. Who can Join?'
              content={
                <>
                  Homeopathic Students, Interns, Post Graduates, beginners & successful practitioners can join the
                  courses.
                  <br />
                  All kinds of Medical & Veterinary Professionals having the desire to learn Original Homeopathy are
                  invited.
                  <br />
                  Lay people who love Homeopathy are welcomed.
                </>
              }
            />

            <AccordianComponent
              title='5. How to join?'
              content={
                <>
                  To join SHAH Family: <a href='/signup'>Form.</a>
                </>
              }
            />

            <AccordianComponent title='6. When to join?' content='Anytime of your convenience. ' />

            <AccordianComponent
              title='7. How to do the course?'
              content={
                <>
                  Each lecture can be seen up to five times, for better learning of the subject. <br />
                  There will be Multi-Choice Questions (MCQs) at the end of several chapters for personal analysis and
                  evaluation.
                  {/* <p>
                    You will have a possibility to “Rate the Video” option below to each video in the course, making us
                    to evaluate our efforts.
                  </p> */}
                  <br />
                  There will be no online tests or final exams after the whole course.
                </>
              }
            />

            <AccordianComponent
              title='8. What Materials shall be provided from the course?'
              content={
                <>
                  Relevant notes can be downloaded from the e-learning platform as per the course chosen. <br />
                  Please remember that all the material given to you during the tenure of the course is having “Forensic
                  Watermarking” being copyrighted literature.
                </>
              }
            />

            <AccordianComponent
              title='9. Why to join?'
              content={
                <>
                  <i>“Original Homeopathy is Simple Homeopathy!”</i>
                  <br />
                  {/* <p>
                    It is understood that a "Homeopaths have a strong desire to practice homeopathy but they are
                    confused in understanding different topics of Homeopathy and lacking its clinical application…"
                  </p> */}
                  These courses will bring confusion to clarity, timidity to confidence, from unknown to known, from
                  naïve to experience, and from negativity to positivity towards Homeopathy for their sacred mission of
                  healing the sick!
                  <br />
                  To feel & say, “Yes, I am proud to be a Homeopath!”
                </>
              }
            />

            <AccordianComponent
              title='10. What to do if we have any query?'
              content={
                <>
                  Following are the facilities for the course:
                  <ul>
                    <li>
                      <b> Personal Discussion: </b> There is a section of “coach” in “SHAH Courses” mobile app. which
                      enables you to make a personal chat with your assigned coach for whatever queries you have.
                    </li>
                    <li>
                      <b> Public Discussion: </b> E-Learning @ SHAH has Forum for Public Discussion with which you can
                      interact with other students doing the course.
                    </li>
                    <li>
                      <b>Meet your Mentor LIVE:</b>
                      You will have a meeting with Dr. Krutik Shah every fortnightly, for doubt solving sessions for the
                      total tenure of your course.
                    </li>
                  </ul>
                </>
              }
            />

            <AccordianComponent
              title='11. What else shall we have to study other than the course material?'
              content={
                <>
                  “Go to the roots to have the best of fruits.” <br />
                  Upon your interest in studying, we advise you to study the “Original Homeopathic Literature from the
                  source books.”
                </>
              }
            />

            <AccordianComponent
              title='12. What will be the fees for the course?'
              content={
                <>
                  E-Learning @ SHAH is a humble tribute at the lotus feet of Dr. Samuel Hahnemann by Dr. Krutik Shah. It
                  is an attempt to bring a “Digital form of Organon of Medicine” to serve the generations to come.
                  <br />
                  Bonus points collected upon correct answers in the Clinical Quiz section of your course will enable
                  you to take your subsequent courses at a reduced cost.
                  <br />
                  All the courses offered by SHAH are having affordable prices. If you find it inefficient to purchase
                  the desired course as per the fees mentioned on it – Kindly visit
                  <a href='/scholarships'> Scholarship @ SHAH </a>
                  page and confirm your eligibility to get the course for “FREE.”
                </>
              }
            />

            <AccordianComponent
              title='13. Will I get some discount in the fees?'
              content={
                <>
                  <p>Yes. There are two possibilities for it.</p>
                  <ol>
                    <li>
                      <b> Group Study:</b> If you are 15 people, you can apply for a group study. The course will be
                      offered to you at a reduced cost with specific obligations to be satisfied from your side. Kindly
                      contact us for the same.
                    </li>
                    <li>
                      <b> Bonus Points from Correct Answers:</b> Bonus Points collected upon correct answers to our MCQs
                      given at the end of several chapters, will give you the benefit of buying your next course at a
                      much discounted price.
                    </li>
                  </ol>
                </>
              }
            />

            <AccordianComponentActive
              title='14. How will be the final exams?'
              content={
                <>
                  There will be no final exams.
                  <br />
                  SHAH believes your patients will take your actual exams.
                  <br />
                  However, “Clinical Quiz Section” is given explicitly, at the end of several chapters for your
                  qualitative evaluation.
                </>
              }
            />

            <AccordianComponentActive
              title='15. What if I don’t attend these Clinical Quiz?'
              content={
                <>
                  <p>It is a must. </p>

                  <p>If Clinical Quiz remains unanswered, then your completion certificate will not be generated.</p>

                  <p>In the Clinical Quiz section after each chapter,</p>
                  <ul>
                    <li> You will have several MCQ or Yes/No questions. </li>
                    <li> You need to answer them in specific minutes.</li>
                    <li>An automatic timer will be set, which you cannot pause.</li>
                  </ul>
                  <p>
                    SHAH is very sure that you will answer well as everything must be fresh in your memory! Bonus Points
                    collected upon correct answers to the Clinical Quiz will benefit you from buying your next course at
                    a much discounted price.
                  </p>
                </>
              }
            />

            <AccordianComponent
              title='16. Which internet browser we should use for the best quality learning?'
              content={
                <>
                  "Google Chrome" internet browser is advisable on your computer/laptop.
                  <br />
                  SHAH also use <a href='/terms-conditions'>cookies</a> for proving quality services.
                  <br />
                  Android and iOS mobile users can download the “SHAH Courses” application from the play store and app
                  store for the best quality learning in Homeopathy!
                </>
              }
            />

            <AccordianComponent
              title='17. Shall we receive the Diploma / Post Graduate Diploma or Certificate from SHAH?'
              content={
                <>
                  More than 50% of the total points of the Clinical Quiz section, if gathered, will be the result of
                  your course.
                  <br />
                  A prestigious Certificate from Samuel Hahnemann Academy for Homeopathy – SHAH will be awarded.
                  <br />A physical copy of your certificate and the badge of honour from SHAH will be couriered to you
                  at the address you have given to us.
                </>
              }
            />

            <AccordianComponent
              title='18. What will be language in the course?'
              content={
                <>
                  "It will be in simple English only in order to understand the lecture in a better way." <br />
                  SHAH is coming up with their unique Organon lectures in multiple international languages with
                  translated subtitles.
                  <br /> This will enable the teaching of “Digital Organon” to reach across the globe, as language will
                  not be the barrier.
                </>
              }
            />

            <AccordianComponent
              title='19. Can we take a video or audio of the lecture we are attending?'
              content={
                <>
                  No, <br />
                  <i>
                    "Every audio, video and document of this platform is been encrypted with Forensic watermarking. Cut,
                    copy, reproduction, forward and allied activities in any form whatsoever of the work either in parts
                    or in full, is punishable offence, which you may please note. All the copyright & literature related
                    to this work qualifies as protected and proprietary data. No part of this work may be reproduced or
                    utilised in any form/by any means, electronic/mechanical including photocopying, recording/by any
                    information storage & retrieval system without permission in writing. Hence, we would encourage the
                    users to refrain from any misadventures of causing any breach to the same. Any breach will result in
                    legal action which please note."
                  </i>
                </>
              }
            />

            <AccordianComponent
              title='20. Is this a Government Recognized Course?'
              content={
                <>
                  No, <br />
                  "This is a vocational course which will sharpen your knowledge of Original Homeopathy as a
                  Hahnemannian Homeopath & will surely contribute to a very great extent, in for your mission of healing
                  the sick!"
                </>
              }
            />

            <AccordianComponent
              title='21. I am not a Homeopath but I love Homeopathy & I want to learn the art and science of healing; can I do this course?'
              content={
                <>
                  Yes, E-Learning @ SHAH offers homeopathic courses for Lay People who love Homeopathy. <br />
                  People from any other professions deeply interested to learn Homeopathy can join the courses. <br />
                  The certificate awarded after completing the course will never be a license to practice homeopathy in
                  the country you live in.
                </>
              }
            />

            <AccordianComponent
              title='22. Will It help to get a license to practice in my country?'
              content='The certificate awarded by SHAH does not confer a legal right to practice homeopathy automatically. Candidates must comply with the relevant laws of their country.'
            />

            <AccordianComponent
              title='23. Can I see the clinical application of the teaching at SHAH?'
              content={
                <>
                  Yes. It is possible, but for that, you have to join
                  <a href='/shah'> Courses @ SHAH </a>.
                  <br />
                  Courses @ SHAH are the clinical courses comprised of classroom teaching and live hospital training
                  under the direct guidance of Dr. Krutik Shah.
                  <br />
                  You need to stay in the Milk City of India, Anand, for the entire duration of your course. The team of
                  SHAH can design customized courses depending upon the need of the National or International Group
                  under the guidance of Dr. Krutik Shah.
                  <br />
                  Accommodations, Transportations, and safety of the International Delegates shall be the
                  responsibilities of SHAH. Kindly visit{' '}
                  <a href='https://www.drkrutikshah.com/how-to-reach-us.php'>how to reach us? & where to stay?</a>
                </>
              }
            />

            <AccordianComponent
              title='24. Important Announcement for E-Learning @ SHAH'
              content={
                <>
                  SHAH is known for providing quality education with self-discipline!
                  <br />
                  If you are inactive in learning from the platform for more than three months without prior notice,
                  either from the beginning of the course or in the tenure of the course, it will lead to temporary
                  freezing of your account.
                  <br />
                  You will have to request us again for its re-activation, and then only it will be activated.
                </>
              }
            />
            <AccordianComponent
              title='25. Can I watch my course curriculum videos randomly?'
              content={
                <>
                  All the topics mentioned in the curriculum are of great practical use and designed with great care.
                  <br />
                  Therefore, Random selection and watching of videos are not permitted. The next video will get open
                  automatically once the existing one is about to get over!
                </>
              }
            />
            <AccordianComponent
              title='26. How to study or Watch Videos at E-Learning @ SHAH?'
              content={
                <>
                  The course content and curriculum for learning in each course SHAH offers is arranged with great
                  precision and care.
                  <br />
                  SHAH wishes the learner to study step by step from the each course fully and satisfactorily, which is
                  also linked with Clinical Quiz following the chapters of the curriculum. Therefore, next video will
                  only opens once the previous video is completed 100%.
                </>
              }
            />
            <p style={{ textAlign: 'center' }}>
              I have some Queries: Fill a{' '}
              <a style={{ color: 'orangered' }} href='/contact'>
                Contact Form
              </a>{' '}
            </p>
          </div>
          <Footer />
        </Suspense>

        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>Organon with SHAH | Samuel Hahnemann Academy for Homeopathy - FAQ</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organon with SHAH',
              name: 'Organon with SHAH | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <div className='desktop'>
            <DesktopNavBar />

            <Hero title='FAQs' img='images/FAQ.jpg' />

            <div
              className='
-content'
              style={{ marginTop: 12, padding: 12 }}
            >
              <AccordianComponent
                title='1. What these courses are all about?'
                content={
                  <>
                    It is the only e-learning program in the world giving exclusive education in the subject of Organon
                    of Medicine and Homeopathic Philosophy.
                    <br /> These vocational clinical courses are based on the highest educational standard following the
                    footprints of Dr. Samuel Hahnemann & Dr. Kent, taught by Proud Child of Dr. Samuel Hahnemann Dr.
                    Krutik Shah directly.
                  </>
                }
              />
              <AccordianComponent
                title='2. How much will be the duration of each lecture?'
                content={
                  <>
                    E-Learning @ SHAH has approximately 150 hours of lectures in the subject of Organon of Medicine in
                    multiple courses. More than 50 hours will be added in a month to come.
                    <br />
                    Each lecture has been made short into multiple small lectures to give you easy, attractive and
                    efficient learning in the easiest possible form; so that you can learn the best, even when you are
                    traveling or walking….!! <br />
                    All the lectures have undergone thorough audio and video treatment to give you the best experience
                    from whatever device you are using. However, it is advisable to use microphones for the best audio
                    experience from the lecture.
                    {/* <br />
                    <br />
                    <ul>
                      <li>
                        <u>Dr. Hahnemann’s Digital Organon of Medicine:</u>
                        <br />
                        <p>Each aphorism has been placed differently with or without its footnote(s).</p>
                        <br />
                        <p style={{ marginTop: -26, paddingRight: 9 }}>
                          <i>
                            Therefore, each lecture on each aphorism has been made separately, ranging from 5 minutes to
                            35 minutes approximately to give attractive and easy learning so that you can learn the
                            best, even when you are traveling or walking….!!
                          </i>
                        </p>
                      </li>

                      <li>
                        <u>Organon - Access to Success Course Series:</u>
                        <br />
                        <p>
                          <i>
                            Each lecture has been made short into multiple small lectures to give efficient but intense
                            learning in the easiest possible form.
                          </i>
                        </p>
                      </li>
                    </ul> */}
                  </>
                }
              />

              <AccordianComponent
                title='3. What are the duration of courses?'
                content={
                  <>
                    Different courses have different tenures ranging from one month to one year.
                    <br />
                    All the courses can be done over & above professional hours.
                    <br />
                    If you want to break the learning due to some reasons, a mentor assigned to you for the course
                    should be requested, and you may resume it whenever you want.
                  </>
                }
              />

              <AccordianComponent
                title='4. Who can Join?'
                content={
                  <>
                    Homeopathic Students, Interns, Post Graduates, beginners & successful practitioners can join the
                    courses.
                    <br />
                    All kinds of Medical & Veterinary Professionals having the desire to learn Original Homeopathy are
                    invited.
                    <br />
                    Lay people who love Homeopathy are welcomed.
                  </>
                }
              />

              <AccordianComponent
                title='5. How to join?'
                content={
                  <>
                    To join SHAH Family: <a href='/signup'>Form.</a>
                  </>
                }
              />

              <AccordianComponent title='6. When to join?' content='Anytime of your convenience. ' />

              <AccordianComponent
                title='7. How to do the course?'
                content={
                  <>
                    Each lecture can be seen up to five times, for better learning of the subject.
                    <br />
                    There will be Multi-Choice Questions (MCQs) at the end of several chapters for personal analysis and
                    evaluation.
                    <br />
                    {/* <p>
                      You will have a possibility to “Rate the Video” option below to each video in the course, making
                      us to evaluate our efforts.
                    </p> */}
                    There will be no online tests or final exams after the whole course.
                  </>
                }
              />

              <AccordianComponent
                title='8. What Materials shall be provided from the course?'
                content={
                  <>
                    Relevant notes can be downloaded from the e-learning platform as per the course chosen.
                    <br />
                    Please remember that all the material given to you during the tenure of the course is having
                    “Forensic Watermarking” being copyrighted literature.
                  </>
                }
              />

              <AccordianComponent
                title='9. Why to join?'
                content={
                  <>
                    <i>“Original Homeopathy is Simple Homeopathy!”</i>
                    <br />
                    {/* <p>
                      It is understood that a "Homeopaths have a strong desire to practice homeopathy but they are
                      confused in understanding different topics of Homeopathy and lacking its clinical application…"
                    </p> */}
                    These courses will bring confusion to clarity, timidity to confidence, from unknown to known, from
                    naïve to experience, and from negativity to positivity towards Homeopathy for their sacred mission
                    of healing the sick!
                    <br />
                    To feel & say, “Yes, I am proud to be a Homeopath!”
                  </>
                }
              />

              <AccordianComponent
                title='10. What to do if we have any query?'
                content={
                  <>
                    Following are the facilities for the course:
                    <ul>
                      <li>
                        <b> Personal Discussion: </b> There is a section of “coach” in “SHAH Courses” mobile app. which
                        enables you to make a personal chat with your assigned coach for whatever queries you have.
                      </li>
                      <li>
                        <b> Public Discussion: </b> E-Learning @ SHAH has Forum for Public Discussion with which you can
                        interact with other students doing the course.
                      </li>
                      <li>
                        <b>Meet your Mentor LIVE:</b>
                        You will have a meeting with Dr. Krutik Shah every fortnightly, for doubt solving sessions for
                        the total tenure of your course.
                      </li>
                    </ul>
                  </>
                }
              />

              <AccordianComponent
                title='11. What else shall we have to study other than the course material?'
                content={
                  <>
                    “Go to the roots to have the best of fruits.” <br />
                    Upon your interest in studying, we advise you to study the “Original Homeopathic Literature from the
                    source books.”
                  </>
                }
              />

              <AccordianComponent
                title='12. What will be the fees for the course?'
                content={
                  <>
                    E-Learning @ SHAH is a humble tribute at the lotus feet of Dr. Samuel Hahnemann by Dr. Krutik Shah.
                    It is an attempt to bring a “Digital form of Organon of Medicine” to serve the generations to come.
                    <br />
                    Bonus points collected upon correct answers in the Clinical Quiz section of your course will enable
                    you to take your subsequent courses at a reduced cost.
                    <br />
                    All the courses offered by SHAH are having affordable prices. If you find it inefficient to purchase
                    the desired course as per the fees mentioned on it – Kindly visit
                    <a href='/scholarships'> Scholarship @ SHAH </a>
                    page and confirm your eligibility to get the course for “FREE.”
                  </>
                }
              />

              <AccordianComponent
                title='13. Will I get some discount in the fees?'
                content={
                  <>
                    <p>Yes. There are two possibilities for it.</p>
                    <ol>
                      <li>
                        <b> Group Study:</b> If you are 15 people, you can apply for a group study. The course will be
                        offered to you at a reduced cost with specific obligations to be satisfied from your side.
                        Kindly contact us for the same.
                      </li>
                      <li>
                        <b> Bonus Points from Correct Answers:</b> Bonus Points collected upon correct answers to our
                        MCQs given at the end of several chapters, will give you the benefit of buying your next course
                        at a much discounted price.
                      </li>
                    </ol>
                  </>
                }
              />

              <AccordianComponentActive
                title='14. How will be the final exams?'
                content={
                  <>
                    There will be no final exam <br />
                    SHAH believes your patients will take your actual exams. <br />
                    However, “Clinical Quiz Section” is given explicitly, at the end of several chapters for your
                    qualitative evaluation.
                  </>
                }
              />

              <AccordianComponentActive
                title='15. What if I don’t attend these Clinical Quiz?'
                content={
                  <>
                    <p>It is a must. </p>

                    <p>If Clinical Quiz remains unanswered, then your completion certificate will not be generated.</p>

                    <p>In the Clinical Quiz section after each chapter,</p>
                    <ul>
                      <li> You will have several MCQ or Yes/No questions. </li>
                      <li> You need to answer them in specific minutes.</li>
                      <li>An automatic timer will be set, which you cannot pause.</li>
                    </ul>
                    <p>
                      SHAH is very sure that you will answer well as everything must be fresh in your memory! Bonus
                      Points collected upon correct answers to the Clinical Quiz will benefit you from buying your next
                      course at a much discounted price.
                    </p>
                  </>
                }
              />

              <AccordianComponent
                title='16. Which internet browser we should use for the best quality learning?'
                content={
                  <>
                    "Google Chrome" internet browser is advisable on your computer/laptop.
                    <br />
                    SHAH also use <a href='/terms-conditions'>cookies</a> for proving quality services.
                    <br />
                    Android and iOS mobile users can download the “SHAH Courses” application from the play store and app
                    store for the best quality learning in Homeopathy!
                  </>
                }
              />

              <AccordianComponent
                title='17. Shall we receive the Diploma / Post Graduate Diploma or Certificate from SHAH?'
                content={
                  <>
                    More than 50% of the total points of the Clinical Quiz section, if gathered, will be the result of
                    your course.
                    <br />
                    A prestigious Certificate from Samuel Hahnemann Academy for Homeopathy – SHAH will be awarded.
                    <br />A physical copy of your certificate and the badge of honour from SHAH will be couriered to you
                    at the address you have given to us.
                  </>
                }
              />

              <AccordianComponent
                title='18. What will be language in the course?'
                content={
                  <>
                    "It will be in simple English only in order to understand the lecture in a better way." <br />
                    SHAH is coming up with their unique Organon lectures in multiple international languages with
                    translated subtitles.
                    <br /> This will enable the teaching of “Digital Organon” to reach across the globe, as language
                    will not be the barrier.
                  </>
                }
              />

              <AccordianComponent
                title='19. Can we take a video or audio of the lecture we are attending?'
                content={
                  <>
                    No, <br />
                    <i>
                      "Every audio, video and document of this platform is been encrypted with Forensic watermarking.
                      Cut, copy, reproduction, forward and allied activities in any form whatsoever of the work either
                      in parts or in full, is punishable offence, which you may please note. All the copyright &
                      literature related to this work qualifies as protected and proprietary data. No part of this work
                      may be reproduced or utilised in any form/by any means, electronic/mechanical including
                      photocopying, recording/by any information storage & retrieval system without permission in
                      writing. Hence, we would encourage the users to refrain from any misadventures of causing any
                      breach to the same. Any breach will result in legal action which please note."
                    </i>
                  </>
                }
              />

              <AccordianComponent
                title='20. Is this a Government Recognized Course?'
                content={
                  <>
                    No, <br />
                    "This is a vocational course which will sharpen your knowledge of Original Homeopathy as a
                    Hahnemannian Homeopath & will surely contribute to a very great extent, in for your mission of
                    healing the sick!"
                  </>
                }
              />

              <AccordianComponent
                title='21. I am not a Homeopath but I love Homeopathy & I want to learn the art and science of healing; can I do this course?'
                content={
                  <>
                    Yes, E-Learning @ SHAH offers homeopathic courses for Lay People who love Homeopathy. <br />
                    People from any other professions deeply interested to learn Homeopathy can join the courses. <br />
                    The certificate awarded after completing the course will never be a license to practice homeopathy
                    in the country you live in.
                  </>
                }
              />

              <AccordianComponent
                title='22. Will It help to get a license to practice in my country?'
                content='The certificate awarded by SHAH does not confer a legal right to practice homeopathy automatically. Candidates must comply with the relevant laws of their country.'
              />

              <AccordianComponent
                title='23. Can I see the clinical application of the teaching at SHAH?'
                content={
                  <>
                    Yes. It is possible, but for that, you have to join
                    <a href='/shah'> Courses @ SHAH </a>.
                    <br />
                    Courses @ SHAH are the clinical courses comprised of classroom teaching and live hospital training
                    under the direct guidance of Dr. Krutik Shah.
                    <br />
                    You need to stay in the Milk City of India, Anand, for the entire duration of your course. The team
                    of SHAH can design customized courses depending upon the need of the National or International Group
                    under the guidance of Dr. Krutik Shah.
                    <br />
                    Accommodations, Transportations, and safety of the International Delegates shall be the
                    responsibilities of SHAH. Kindly visit{' '}
                    <a href='https://www.drkrutikshah.com/how-to-reach-us.php'>how to reach us? & where to stay?</a>
                  </>
                }
              />

              <AccordianComponent
                title='24. Important Announcement for E-Learning @ SHAH'
                content={
                  <>
                    SHAH is known for providing quality education with self-discipline!
                    <br />
                    If you are inactive in learning from the platform for more than three months without prior notice,
                    either from the beginning of the course or in the tenure of the course, it will lead to temporary
                    freezing of your account.
                    <br />
                    You will have to request us again for its re-activation, and then only it will be activated.
                  </>
                }
              />
              <AccordianComponent
                title='25. Can I watch my course curriculum videos randomly?'
                content={
                  <>
                    All the topics mentioned in the curriculum are of great practical use and designed with great care.
                    <br />
                    Therefore, Random selection and watching of videos are not permitted. The next video will get open
                    automatically once the existing one is about to get over!
                  </>
                }
              />
              <AccordianComponent
                title='26. How to study or Watch Videos at E-Learning @ SHAH?'
                content={
                  <>
                    The course content and curriculum for learning in each course SHAH offers is arranged with great
                    precision and care.
                    <br />
                    SHAH wishes the learner to study step by step from the each course fully and satisfactorily, which
                    is also linked with Clinical Quiz following the chapters of the curriculum. Therefore, next video
                    will only opens once the previous video is completed 100%.
                  </>
                }
              />
              <p style={{ textAlign: 'center' }}>
                I have some Queries: Fill a{' '}
                <a style={{ color: 'orangered' }} href='/contact'>
                  Contact Form
                </a>{' '}
                .
              </p>
            </div>
            <div style={{ marginTop: -31 }}>
              <Footer />
            </div>
          </div>
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  }
}

export default Faqs
