import React, { Suspense, lazy } from 'react'

import useWindowDimesions from '../components/useWindowDimesions'
import '../styles/index.css'
import SplashScreen from './SplashScreen'
import { Helmet } from 'react-helmet'
// import Footer from '../components/Footer'
const Footer = lazy(() => import('../components/Footer'))
// import Hero from '../components/Hero'
const Hero = lazy(() => import('../components/Hero'))
// import MobileNavBar from '../components/MobileNavBar'
const MobileNavBar = lazy(() => import('../components/MobileNavBar'))
// import DesktopNavBar from '../components/DesktopNavBar'
const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))
// import CourseGrid from '../components/CourseGrid'
const CourseGrid = lazy(() => import('../components/CourseGrid'))
// import DDigitalOrganon from './Courses/DigitalOrganon'
const DDigitalOrganon = lazy(() => import('./Courses/DigitalOrganon'))

const DigitalOrganon = () => {
  // const [open, isOpen] = useState(false)
  const { width } = useWindowDimesions()
  if (width < 999) {
    return (
      <>
        <Helmet>
          <title>
            Digital Organon | Samuel Hahnemann Academy for Homeopathy - Dr. Hahnemann’s Digital Organon of Medicine
          </title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Digital Organon',
              name: 'Digital Organon | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <MobileNavBar />
          <Hero title='Dr. Hahnemann’s Digital Organon of Medicine' img='/images/digitalOrganon.jpg' />
          <DDigitalOrganon />
          <CourseGrid gridTitle='Courses' category='digitalorganon' />
          <Footer />
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Digital Organon',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>
            Digital Organon | Samuel Hahnemann Academy for Homeopathy - Dr. Hahnemann’s Digital Organon of Medicine
          </title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Digital Organon',
              name: 'Digital Organon | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <div className='desktop'>
            <DesktopNavBar />

            <Hero title='Dr. Hahnemann’s Digital Organon of Medicine' img='/images/digitalOrganon.jpg' />
            <DDigitalOrganon />
            <CourseGrid gridTitle='Courses' category='digitalorganon' />
            <Footer />
          </div>
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Digital Organon',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  }
}

export default DigitalOrganon
