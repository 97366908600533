import React, { useState, useEffect, useMemo, Suspense, lazy } from 'react'
import '../styles/courseviewer.scss'
import left from '../img/left-arrow.png'
import headphone from '../img/headphones.png'
import axios from 'axios'
// import ViewChapterDropdown from '../components/ViewChapterDropdown'
const ViewChapterDropdown = lazy(() => import('../components/ViewChapterDropdown'))
// import VideoPlay from '../components/VideoPlay'
const VideoPlay = lazy(() => import('../components/VideoPlay'))
// import VideoOverview from '../components/VideoOverview'
import { useParams } from 'react-router-dom'
import useWindowDimensions from '../components/useWindowDimesions'
import home from '../img/go-to-home.png'
import SplashScreen from './SplashScreen'
import { Helmet } from 'react-helmet'

import { callApi } from '../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const updateEndpoint = {
  ...defaults.methods.POST,
  uri: '/api/feedback/add-feedback'
}

const viewFeedback = {
  ...defaults.methods.GET,
  uri: '/api/feedback/view-feedback'
}

// let index = 0
// let index1 = 0

export default function CourseViewer() {
  // let finalArray = []

  const { width } = useWindowDimensions()
  const params = useParams()
  // const slug = props.match.params.slug
  const slug = params.slug
  console.log(`🚀  ~ file: CourseViewer.js:59 ~ CourseViewer ~ slug:`, slug)

  const [course, setCourse] = useState([])

  // const [review, setReview] = useState('')
  const [getLessonsValue, setGetLessonsValue] = useState({})

  const [activeVideo, setActiveVideo] = useState({})
  const [recallApi, setRecallApi] = useState()
  const [initialVideo, setInitialVideo] = useState(null)
  const [allVideos, setAllVideos] = useState(null)

  const [nextIndex, setNextIndex] = useState(false)
  const [prevIndex, setPrevIndex] = useState(false)
  const [nextButtonVideo, setNextButtonVideo] = useState('')
  const [previewButtonVideo, setPreviewButtonVideo] = useState('')

  const [lessonStoreValue, setLessonStoreValue] = useState([])

  const [lessonResponseValue, setLessonResponseValue] = useState({})

  const [parseNextValue, setParseNextValue] = useState({})
  const [parsePrevValue, setParsePrevValue] = useState({})

  const [chaptersData, setChaptersData] = useState([])

  const [feedBackReview, setFeedBackReview] = useState('')
  // const [rating, setRating] = useState('')

  // const [responseData, setResponseData] = useState('')

  const [progress, setProgress] = useState(0)

  // const courseId = chaptersData[0]?.courseID

  const id = localStorage.getItem('mongoId')

  const viewFeedbackResponse = async () => {
    if (course.length !== 0) {
      const body = {
        student_id: id,
        course_id: course[0]._id,
        chapter_id: getLessonsValue?.chapterid || getLessonsValue?.dataID?.chapterid,
        lesson_id: getLessonsValue?._id || getLessonsValue?.dataID?._id
      }

      await callApi({
        uriEndPoint: viewFeedback,
        query: {
          student_id: body.student_id,
          course_id: body.course_id,
          chapter_id: body.chapter_id,
          lesson_id: body.lesson_id
        }
      })
        .then(response => {
          if (response?.data) {
            setFeedBackReview(response?.data?.message || '')
          } else {
            setFeedBackReview('')
          }
        })
        .catch(e => console.log(e))
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const body = {
      student_id: id,
      course_id: course[0]._id,
      chapter_id: getLessonsValue?.chapterid || getLessonsValue?.dataID?.chapterid,
      lesson_id: getLessonsValue?._id || getLessonsValue?.dataID?._id,
      message: feedBackReview
    }

    try {
      const updateResponse = await callApi({ uriEndPoint: updateEndpoint, body })
      console.log('Update response:', updateResponse)

      if (updateResponse.statusCode === 201) {
        alert('Thank you for your feedback! 🎉')
      }
    } catch (error) {
      console.log('Error:', error)
      alert('Your feedback was not added successfully')
    }
  }

  let allLessonData = []

  const combinedData = chaptersData.map(chapter => {
    const matchingLessons = lessonStoreValue.filter(lesson => lesson.chapterid === chapter._id)

    return {
      ...chapter,
      lessons: matchingLessons
    }
  })

  combinedData.map(item => {
    allLessonData.push(...item.lessons)
  })

  async function FetchData() {
    await axios
      .get('/api/course?type=front-end-api')
      .then(res => {
        const array = []
        res.data.courses.map(r => {
          if (r.slug === slug) {
            array.push(r)
          } else {
            if (slug.includes(r.slug)) {
              array.push({ ...r, chapter_id: slug })
            }
          }
        })

        setCourse(array)
      })
      .catch(e => alert(e))
  }

  // function ReviewSubmit(e) {
  //   e.preventDefault()

  //   setReview('')
  //   alert('Review Submit')
  // }

  const apiCall = async () => {
    if (Object.keys(getLessonsValue).length !== 0) {
      console.log(`🚀  ~ file: CourseViewer.js:202 ~ apiCall ~ getLessonsValue:`, getLessonsValue.lessonNum)

      // const userData = localStorage.getItem('userId')
      const userData = localStorage.getItem('mongoId')
      const body = {
        lessonId: getLessonsValue?._id === undefined ? getLessonsValue.dataID._id : getLessonsValue._id,
        userId: userData,
        currentLesson: getLessonsValue.lessonNumber || getLessonsValue.lessonNum || '1',
        courseId: course[0]?._id
      }

      await axios
        .post('/api/stream/update-stream', body)
        .then(async res => {
          await setActiveVideo(res.data.data)
          setRecallApi(Math.random(Math.random() * 20).toString())
        })
        .catch(e => alert(e))
    }
  }

  const obj = useMemo(() => {
    let url =
      nextIndex === false && prevIndex === false
        ? getLessonsValue?.lessonIframe ?? allVideos?.lessonIframe
        : prevIndex === true
        ? parsePrevValue?.lessonIframe
        : parseNextValue?.lessonIframe

    let objNew = {}
    if (url) {
      const baseUrl = 'https://player.vdocipher.com/v2'
      const queryString = new URL(url).search
      const paramsNew = new URLSearchParams(queryString)

      const filteredParams = {}
      for (const [key, value] of paramsNew) {
        if (!url.startsWith(baseUrl) || key !== '') {
          filteredParams[key] = value
        }
      }

      const filteredQueryString = new URLSearchParams(filteredParams).toString()

      const params1 = new URLSearchParams(filteredQueryString)

      for (const [key, value] of params1) {
        objNew[key] = value
      }
    }

    return objNew
  }, [getLessonsValue, allVideos, nextIndex, prevIndex, parsePrevValue, parseNextValue])

  const currentIndex = allLessonData.findIndex(item => item._id === lessonResponseValue._id)

  const handleNextVideo = async () => {
    const nextIndex = currentIndex + 1

    if (nextIndex < allLessonData.length) {
      const nextVideo = allLessonData[nextIndex]
      setLessonResponseValue(nextVideo)
      setParseNextValue(nextVideo)
      setNextButtonVideo(nextVideo.lessonIframe)
      setParsePrevValue(null)
      setPrevIndex(false)
      setNextIndex(true)
    }
  }

  const handlePreviousVideo = async () => {
    const prevIndex = currentIndex - 1

    if (prevIndex >= 0) {
      const prevVideo = allLessonData[prevIndex]
      setLessonResponseValue(prevVideo)
      setParsePrevValue(prevVideo)
      setPreviewButtonVideo(prevVideo.lessonIframe)
      setParseNextValue(null)
      setNextIndex(false)
      setPrevIndex(true)
    }
  }

  useEffect(() => {
    FetchData()
  }, [])

  useEffect(() => {
    viewFeedbackResponse()
  }, [getLessonsValue])

  if (course.length !== 0) {
    return (
      <>
        <Helmet>
          <title>Organon with SHAH - Course View</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organon with SHAH',
              name: 'Organon with SHAH | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          {course.map(res => {
            return (
              <div key={res._id} className='full'>
                <div className='navbar'>
                  <div
                    style={{
                      display: width <= 591 ? 'none' : 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      className='navbar-home'
                      style={{}}
                      onClick={() => {
                        window.location.href = '/'
                      }}
                    >
                      <img src={home} />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <div className='progress' data-progress={style?.width || 0}></div> */}
                      <div className='progress-bar'>
                        <div
                          className='progress'
                          style={{ width: `${progress.width ? Number(progress.width.split('%')[0]) : 0}%` }}
                        ></div>
                        <button style={{ visibility: 'hidden' }}>Update Progress</button>
                      </div>

                      <p style={{ marginLeft: '2px' }}>{progress?.width?.length === 1 ? 0 + '%' : progress?.width}</p>
                    </div>
                    <h2
                      className='course-title'
                      style={{
                        alignSelf: 'center',
                        justifySelf: 'self-start',
                        display: width <= 591 ? 'none' : 'block'
                      }}
                    >
                      Curriculum
                    </h2>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2
                      className='course-title'
                      style={{ color: '#f58a25', marginLeft: width <= 591 ? '0px' : '25px' }}
                    >
                      <b style={{ fontWeight: 'bolder' }}> {res.nameOfCourse}</b>
                    </h2>
                  </div>

                  <div className='course-button' style={{ alignItems: 'center', gap: 5 }}>
                    <button
                      className='previous-button'
                      onClick={() => {
                        window.open('https://www.samuelhahnemann.org/notes/Acute.pdf', '_blank')
                      }}
                      style={{ backgroundColor: '#5576CF', color: 'white', fontWeight: 'bolder' }}
                    >
                      Notes Download
                    </button>
                    <button
                      className='previous-button'
                      style={{
                        cursor: currentIndex === 0 ? 'default' : 'pointer',
                        backgroundColor: currentIndex === 0 ? 'rgba(25, 0, 99, 0.43)' : 'rgb(25, 0, 99)',
                        color: currentIndex === 0 ? '#ffffff91' : '#fff',
                        border: currentIndex === 0 ? '2px solid #19006308' : '2px solid rgb(25, 0, 99)'
                      }}
                      onClick={() => currentIndex !== 0 && handlePreviousVideo()}
                      disabled={currentIndex === 0 ? true : false}
                    >
                      Previous
                    </button>

                    <button
                      className='next-button'
                      style={{
                        cursor: currentIndex === allLessonData.length - 1 ? 'default' : 'pointer',
                        backgroundColor: currentIndex === allLessonData.length - 1 ? '#ff450066' : '#ff4500',
                        color: currentIndex === allLessonData.length - 1 ? '#ffffff91' : '#fff',
                        border: currentIndex === allLessonData.length - 1 ? '2px solid #ff450000' : '2px solid #ff4500'
                      }}
                      onClick={() => currentIndex !== allLessonData.length - 1 && handleNextVideo()}
                      disabled={currentIndex === allLessonData.length - 1 ? true : false}
                    >
                      Next
                    </button>

                    <div
                      className='navbar-back-course'
                      style={{ cursor: 'pointer', display: width <= 1024 ? 'none' : 'block' }}
                      onClick={() => (window.location.href = '/browse-course')}
                    >
                      <img src={left} />
                    </div>
                  </div>
                </div>

                <div className='two-part'>
                  <div className='one'>
                    <div className='course'>
                      <ViewChapterDropdown
                        courseId={res._id}
                        lessonsValue={e => setGetLessonsValue(e)}
                        activeVideo={activeVideo}
                        setLessonStoreValue={e => setLessonStoreValue(e)}
                        setLessonResponseValue={item => {
                          setLessonResponseValue(item)
                          setNextIndex(false)
                          setPrevIndex(false)
                        }}
                        chapter_id={slug}
                        parseNextValue={parseNextValue}
                        parsePrevValue={parsePrevValue}
                        recallApi={recallApi}
                        lessonResponseValue={lessonResponseValue}
                        firstLessonVideo={video => {
                          setInitialVideo(video)
                          setLessonResponseValue(video)
                        }}
                        allAvailableData={videos => setAllVideos(videos)}
                        handleNextVideo={handleNextVideo}
                        handlePreviousVideo={handlePreviousVideo}
                        setChaptersData={data => setChaptersData(data)}
                        percentage={e => setProgress(e)}
                      />

                      {/* <ViewChapterDropdown
                      courseId={res._id}
                      lessonsValue={e => setGetLessonsValue(e)}
                      activeVideo={activeVideo}
                      setLessonStoreValue={e => setLessonStoreValue(e)}
                      setLessonResponseValue={item => setLessonResponseValue(item)}
                      parseNextValue={parseNextValue}
                      recallApi={recallApi}
                      firstLessonVideo={video => {
                        setInitialVideo(video)
                      }}
                      allAvailableData={videos => setAllVideos(videos)}
                    /> */}
                    </div>
                  </div>

                  <div className='two'>
                    <div className='content'></div>

                    <div className='first-section' style={{ height: 'auto' }}>
                      {initialVideo !== null &&
                        allVideos !== null &&
                        allVideos !== undefined &&
                        Object.keys(obj).length !== 0 && (
                          <VideoPlay
                            playbackInfo={obj.playbackInfo}
                            otp={obj.otp}
                            callNextVideoApi={apiCall}
                            video={nextIndex ? nextButtonVideo : previewButtonVideo}
                            // video={allVideos?.[nextIndex]}
                          />
                        )}

                      {/* <div className='form-content'>
                    <form className='review-form' style={{ width: '90%' }} onSubmit={ReviewSubmit}>
                      <textarea
                        style={{ width: width <= 1024 ? '100%' : '600px' }}
                        value={review}
                        onChange={e => setReview(e.target.value)}
                        placeholder='Please write your questions here that will be answered live by Dr. Krutik Shah in our weekly Master Call'
                      />

                      <button style={{ cursor: 'pointer', width: '175px' }} type='submit'>
                        Submit
                      </button>
                    </form>
                  </div> */}
                    </div>

                    <div className='form-footer-content'>
                      <h2
                        className='audio-notes'
                        style={{
                          marginBottom: '0px',
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '1rem',
                          fontSize: '10px',
                          textAlign: 'center'
                        }}
                        // onClick={() => alert('Lesson notes are unvaliable.')}
                      >
                        <img className='headphone-img' src={headphone} />
                        <br />
                        <span>USE HEADPHONES FOR BETTER EXPERIENCE</span>
                      </h2>
                      <h2
                        className='video-notes'
                        style={{ marginBottom: '1%', marginTop: '0%', textAlign: 'center', fontSize: '10px' }}
                      >
                        Note: The next video will open only if you complete watching this video 100% and mark as
                        complete.
                      </h2>
                    </div>
                    <h2
                      className='notes-download'
                      // onClick={() => alert('Lesson notes are unvaliable.')}
                    >
                      FEEDBACK ON THE LECTURE
                      {/* <div className='star-rating'>
                      <input
                        id='star-5'
                        type='radio'
                        name='rating'
                        value='5'
                        checked={rating === '5'}
                        onChange={e => {
                          setRating(e.target.value)
                        }}
                        // disabled={responseData?.is_apply !== false ? true : false}
                      />
                      <label for='star-5'>
                        <i className='active fa fa-star' aria-hidden='true'></i>
                      </label>
                      <input
                        id='star-4'
                        type='radio'
                        name='rating'
                        value='4'
                        checked={rating === '4'}
                        onChange={e => setRating(e.target.value)}
                        // disabled={responseData?.is_apply !== false ? true : false}
                      />
                      <label for='star-4'>
                        <i className='active fa fa-star' aria-hidden='true'></i>
                      </label>
                      <input
                        id='star-3'
                        type='radio'
                        name='rating'
                        value='3'
                        checked={rating === '3'}
                        onChange={e => setRating(e.target.value)}
                        // disabled={responseData?.is_apply !== false ? true : false}
                      />
                      <label for='star-3'>
                        <i className='active fa fa-star' aria-hidden='true'></i>
                      </label>
                      <input
                        id='star-2'
                        type='radio'
                        name='rating'
                        value='2'
                        checked={rating === '2'}
                        onChange={e => setRating(e.target.value)}
                        // disabled={responseData?.is_apply !== false ? true : false}
                      />
                      <label for='star-2'>
                        <i className='active fa fa-star' aria-hidden='true'></i>
                      </label>
                      <input
                        id='star-1'
                        type='radio'
                        name='rating'
                        value='1'
                        checked={rating === '1'}
                        onChange={e => setRating(e.target.value)}
                        // disabled={responseData?.is_apply !== false ? true : false}
                      />
                      <label for='star-1'>
                        <i className='active fa fa-star' aria-hidden='true'></i>
                      </label>
                    </div> */}
                      <div className='form-content'>
                        <form className='review-form' style={{ width: '90%' }} onSubmit={handleSubmit}>
                          <textarea
                            style={{ width: width <= 1024 ? '100%' : '800px' }}
                            value={feedBackReview}
                            rows={1}
                            // readOnly={responseData?.is_apply === true ? true : false}
                            onChange={e => setFeedBackReview(e.target.value)}
                            placeholder='Please write your query, concern or points to learn better from this lesson directly to Dr. Krutik Shah.'
                          />

                          <button
                            style={{
                              // cursor: responseData?.is_apply === true ? 'none' : 'pointer',
                              // width: '175px',
                              // backgroundColor: responseData?.is_apply === true ? '#ff572282' : '#ff5722',
                              // color: responseData?.is_apply === true ? '#00000087' : '#fff',
                              // border: responseData?.is_apply === true ? '1px solid #ff572282' : '1px solid #ff5722;',
                              marginTop: '10px'
                            }}
                            type='submit'
                            // disabled={responseData?.is_apply !== false ? true : false}
                          >
                            Submit Feedback
                          </button>
                        </form>
                      </div>
                    </h2>
                  </div>
                </div>
              </div>
            )
          })}
        </Suspense>

        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organon with SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  } else {
    return <SplashScreen />
  }
}
