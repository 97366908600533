import axios from 'axios'
import React, { useState, useEffect } from 'react'
// import play from '../../img/play-button.png'

function GetLessonNumber(props) {
  const [lessonNumber, setLessonNumber] = useState('')
  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {
    await axios
      .post('/api/course/all-lessons', {
        id: props.chapterid,
        body: 'front-end-api'
      })
      .then(res => {
        setLessonNumber(res.data.length)

        // console.log(res)
      })
      .catch(e => console.log(e))
  }

  if (lessonNumber > 1) {
    return <>{lessonNumber} Lessons </>
  } else {
    return <>{lessonNumber} Lesson </>
  }
}

function GetQuiz(props) {
  const [Quiz, setQuiz] = useState([])
  useEffect(() => {
    getMyLesson()
  }, [])
  async function getMyLesson() {
    await axios.get(`/api/course/quiz/cha/${props.chapterid}/?type=front-end-api`).then(res => {
      // const FilterLessons = res.data.courses.filter(r=>r.chapterid===props.chapterid)

      const hello = []
      hello.push(res.data)
      setQuiz(hello)
    })
  }

  // function NumberExtractor(text) {
  //   var txt = text
  //   var numb = txt?.match(/\d/g)
  //   numb = numb?.join('')
  //   return numb
  // }

  return (
    <>
      {Quiz.map((res, index) => {
        if (res !== null && res?.questions?.length !== 0 && typeof res === 'object' && res !== null)
          return (
            <div className='lesson' key={index + 'Quiz'}>
              <div className='play-btn'>
                <i className='fa fa-clock-o' aria-hidden='true' style={{ color: 'orangered' }}></i>
              </div>
              <div className='title' style={{ width: '95vw' }}>
                <span style={{ color: 'orangered' }}>
                  <b>Clinical Quiz </b>{' '}
                </span>
              </div>
              <div className='timer' style={{ color: 'orangered' }}>
                {/* {NumberExtractor(res?.time)} */}
                <i className='fa fa-lock' aria-hidden='true'></i>
              </div>
            </div>
          )
      })}
    </>
  )
}

function GetChapters(props) {
  const [dropdown, setDropdown] = useState(false)
  useEffect(() => {
    if (props.drop === false) {
      setDropdown(false)
    } else if (props.drop === true) {
      setDropdown(true)
    }
  }, [props.drop, dropdown])

  return (
    <>
      <div className='title' style={{ marginTop: '2em', marginBottom: '1em', color: 'orangered' }}>
        Chapter - {props.chapterIndex + 1}
      </div>
      <div className='subtitle'>{props.chapterName}</div>
      <div className='course-contents'>
        <GetLessonNumber chapterid={props.id} />{' '}
        {props.clinicalPonits === 0 ? <></> : <>| {props.clinicalPonits} Clinical Points</>}
      </div>

      <>{props.children}</>
      <div className='app-line' />
    </>
  )
}

function GetLessons(props) {
  const checkedBuyCourse = props.checkedBuyCourse

  const [Lessons, setLessons] = useState([])

  useEffect(() => {
    getMyLesson()
  }, [])
  async function getMyLesson() {
    await axios
      .post('/api/course/all-lessons', {
        id: props.chapterid,
        body: 'front-end-api'
      })
      .then(res => {
        setLessons(res.data)
      })
  }

  return (
    <>
      {Lessons.map((res, index) => {
        return (
          <div className='lesson' key={index + 'LessonsApp'}>
            <div className='play-btn'>
              {checkedBuyCourse === false && <i className='fa fa-lock' aria-hidden='true'></i>}
            </div>
            <div className='title' style={{ width: '95vw', color: checkedBuyCourse ? '#0d0d0c' : '#bab8b3' }}>
              {res.lessonName}
            </div>
            <div className='timer' style={{ color: checkedBuyCourse ? 'orangered' : '#d67e4f' }}>
              {res.lessonTime}
            </div>
          </div>
        )
      })}
    </>
  )
}
export default function AppChapterDropdown(props) {
  const id = props.courseId
  const checkedBuyCourse = props.checkedBuyCourse

  // const [dropdown, setDropdown] = useState(false)
  const [Chapters, setChapters] = useState([])

  useEffect(() => {
    getChapters()
  }, [])

  async function getChapters() {
    await axios
      .post('/api/course/all-chapters', {
        id: id,
        body: 'front-end-api'
      })
      .then(res => {
        setChapters(res.data)
      })
  }

  return (
    <>
      <div className='app-course-title app-index-curriculum' style={{ paddingTop: '1.5em' }}>
        {Chapters.map((res, i) => (
          <React.Fragment key={i + 'Chapters'}>
            <GetChapters
              drop={false}
              id={res._id}
              chapterIndex={i}
              chapterName={res.chapterName}
              clinicalPonits={res.clinicalPonits}
            >
              <GetLessons chapterid={res._id} slug={props.slug} checkedBuyCourse={checkedBuyCourse} />
              <GetQuiz chapterid={res._id} />
            </GetChapters>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}
