import React, { lazy, Suspense } from 'react'
import '../styles/Home.scss'
import '../styles/nav.scss'

import useWindowDimesions from '../components/useWindowDimesions'

// import Footer from '../components/Footer'
const Footer = lazy(() => import('../components/Footer'))
// import CourseGrid from '../components/CourseGrid'
const CourseGrid = lazy(() => import('../components/CourseGrid'))
// import DesktopNavBar from '../components/DesktopNavBar'
const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))
// import MobileNavBar from '../components/MobileNavBar'
const MobileNavBar = lazy(() => import('../components/MobileNavBar'))
// import CallToAction from '../components/CallToAction'
const CallToAction = lazy(() => import('../components/CallToAction'))
// import Reviews from '../components/Reviews'
// import ImageSlider from '../components/ImageSlider'
const ImageSlider = lazy(() => import('../components/ImageSlider'))
// import WhyStudyShah from './WhyStudyShah'
const WhyStudyShah = lazy(() => import('./WhyStudyShah'))

// import Trainer from "../components/Trainer";
import CountUp from 'react-countup'
// import ReviewImageSlider from '../components/reviewSlider'
const ReviewImageSlider = lazy(() => import('../components/reviewSlider'))
import { Helmet } from 'react-helmet'
import SplashScreen from './SplashScreen'

const Home = () => {
  const { width } = useWindowDimesions()

  if (width < 999) {
    return (
      <>
        <Helmet>
          <title>Organon with SHAH | Samuel Hahnemann Academy for Homeopathy - Home</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'E-Learning @ SHAH',
              name: 'E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <MobileNavBar />

          <ImageSlider />

          <CourseGrid />

          <div className='about-shah-homepage mobile-home-about'>
            <div className='phone-container'>
              <div className='photo'>
                <img src='/aboutshah.jpg' />
              </div>
              <div
                className='organge-box'
                style={{ height: width < 999 ? '164px' : '220px' }}
                onClick={() => (window.location.href = '/drkrutikshah')}
              >
                <div className='badge-icon'></div>
                <div className='number'>
                  <span className='percentage' data-value='15' data-animation-duration='3500'>
                    <CountUp start={1} end={139} duration={6} delay={3} />
                  </span>
                  +{' '}
                </div>
                <h3 className='badge-title'>First Homeopathic Centurion for Globinars in the World Since COVID19</h3>
              </div>
            </div>
            <p style={{ fontFamily: " 'Jost',sans-serif ", fontSize: '14px', marginTop: '2em' }}>
              <b>Dr. Krutik Shah</b> M.D. (Organon of Medicine)
              <br />
              <b>Proud Child of Dr. Samuel Hahnemann</b>
            </p>

            <h2>Welcome to Organon with SHAH</h2>
            <p>
              Welcome to the world of "Original Homeopathy" and I am sure that it will be a unique experience for you.
            </p>
            <p>
              It is the only e-learning program in the world giving exclusive education on the subject of Organon of
              Medicine and Homeopathic Philosophy. Organon of Medicine is one of most distorted subjects in homeopathy
              from its inception till date, and as a result, we see an increase in the quantity of homeopaths with
              decreasing quality, globally!
            </p>
            {/* <p>
              "Original Homeopathy" has all the angles considered; every possibility is worked for; every philosophy is
              deeply rooted in spirituality, and every theory has arrived from the case and clinical confirmations.
            </p> */}
            <p>
              I believe "Original Homeopathy is Simple Homeopathy" and because it has been forgotten in the present era
              – Homeopathy has become complex & challenging, both to learn and practice!
              <br />
              Original Homeopathy is something what we already know! What we do not know is its clinical application!
            </p>
            <p>
              Dr. Hahnemann’s Digital Organon of Medicine (5th Ed.) is the first complete word-to-word “DIGITAL”
              transformation of the “BOOK” version of Dr. Hahnemann’s Organon of Medicine, clearing almost all the
              problems the readers have been facing while reading the book of Dr. Hahnemann’s Organon of Medicine for
              the best of its use for present and future generations, in order to make the children meet the father, the
              first, dynamically.
              <br />
              We are observing that “The Present generation has lost the ability to read long and for hours together”
              and therefore, the Book Version of Dr. Hahnemann’s Organon of Medicine is converted into the Digital
              Organon using the Knowledge of Psychology, to make a boring book too interesting to read!
            </p>
            <p>
              "One should go to the roots, to have the best of fruits." I wish to give you the "Original Homeopathic
              Information in purest possible & simplified way from almost all the source books of Organon of Medicine
              written by our immortal stalwarts using the best version of the technology" rather than giving anything
              “so called new” in Homeopathy.
            </p>
            <p>
              I do not want to impress; I want to inspire! I do not want to be famous; I want to be effective! I wish to
              stay at the feet of Dr. Samuel Hahnemann, providing others the solid foundations for Homeopathic Practice!
            </p>
            <p>
              I sincerely hope 150+ hours of my lectures on the subject of Organon of Medicine will take Homeopaths
              brighter, higher & stronger in their mission of healing the sick.
            </p>
            <p>
              <i>Hail Hahnemann. Hail Homeopathy.</i>
            </p>
          </div>
          <WhyStudyShah />
          {/* <Trainer /> */}
          {/* <Reviews /> */}

          <ReviewImageSlider />

          <CallToAction />
          <div className='mobile-be-the-homeopath'>
            <div className='combine'>
              <div className='photo'>
                <img src='images/groupPhoto.jpg' />
              </div>
              <div className='headMain'>
                <div className='headings'>
                  <h2>
                    Original Homeopathy is <br /> <span>Simple & Easy Homeopathy</span>
                  </h2>
                </div>
                <ul className='vl'>
                  <li>&nbsp;Learn</li>
                  <li>&nbsp;Live</li>
                  <li>&nbsp;Lead</li>
                </ul>
                <button onClick={() => (window.location.href = 'signup')}>BE The HOMEOPATH</button>
              </div>
            </div>
          </div>

          <Footer />
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  } else {
    return (
      <>
        <Suspense fallback={<SplashScreen />}>
          <Helmet>
            <title>Organon with SHAH | Samuel Hahnemann Academy for Homeopathy - Home</title>
            <meta
              name='description'
              content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
            />
            {/* Add more meta tags as needed */}
            <script type='application/ld+json'>
              {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'E-Learning @ SHAH',
                name: 'E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy',
                url: 'https://www.samuelhahnemann.org/'
                // Add more structured data properties
              })}
            </script>
          </Helmet>
          <div className='desktop'>
            <DesktopNavBar />
            <ImageSlider />
          </div>

          <div>
            <CourseGrid />
          </div>

          <div className='shah-message'>
            <div className='container'>
              <div className='photo-container'>
                <div className='photo'>
                  <img src='/aboutshah.jpg' />

                  <div
                    className='organge-box'
                    style={{ height: width < 999 ? '164px' : '220px' }}
                    onClick={() => (window.location.href = '/drkrutikshah')}
                  >
                    <div className='badge-icon'></div>

                    <div className='number'>
                      <span className='percentage' data-value='15' data-animation-duration='3500'>
                        <CountUp start={1} end={139} duration={6} delay={3} />
                      </span>
                      +{' '}
                    </div>

                    <h3 className='badge-title'>
                      First Homeopathic Centurion for Globinars in the World Since COVID19
                    </h3>
                  </div>
                </div>
              </div>

              <div className='content'>
                <div className='all-text'>
                  <h2 className='sub-header'>Welcome to Organon with SHAH</h2>

                  <p>
                    Welcome to the world of "Original Homeopathy" and I am sure that it will be a unique experience for
                    you.
                  </p>
                  <p>
                    It is the only e-learning program in the world giving exclusive education on the subject of Organon
                    of Medicine and Homeopathic Philosophy. Organon of Medicine is one of most distorted subjects in
                    homeopathy from its inception till date, and as a result, we see an increase in the quantity of
                    homeopaths with decreasing quality, globally!
                  </p>
                  {/* <p>
                    "Original Homeopathy" has all the angles considered; every possibility is worked for; every
                    philosophy is deeply rooted in spirituality, and every theory has arrived from the case and clinical
                    confirmations.
                  </p> */}
                  <p>
                    I believe "Original Homeopathy is Simple Homeopathy" and because it has been forgotten in the
                    present era – Homeopathy has become complex & challenging, both to learn and practice!
                    <br />
                    Original Homeopathy is something what we already know! What we do not know is its clinical
                    application!
                  </p>
                  <p>
                    Dr. Hahnemann’s Digital Organon of Medicine (5th Ed.) is the first complete word-to-word “DIGITAL”
                    transformation of the “BOOK” version of Dr. Hahnemann’s Organon of Medicine, clearing almost all the
                    problems the readers have been facing while reading the book of Dr. Hahnemann’s Organon of Medicine
                    for the best of its use for present and future generations, in order to make the children meet the
                    father, the first, dynamically.
                    <br />
                    We are observing that “The Present generation has lost the ability to read long and for hours
                    together” and therefore, the Book Version of Dr. Hahnemann’s Organon of Medicine is converted into
                    the Digital Organon using the Knowledge of Psychology, to make a boring book too interesting to
                    read!
                  </p>
                  <p>
                    "One should go to the roots, to have the best of fruits." I wish to give you the "Original
                    Homeopathic Information in purest possible & simplified way from almost all the source books of
                    Organon of Medicine written by our immortal stalwarts using the best version of the technology"
                    rather than giving anything “so called new” in Homeopathy.
                  </p>
                  <p>
                    I do not want to impress; I want to inspire! I do not want to be famous; I want to be effective! I
                    wish to stay at the feet of Dr. Samuel Hahnemann, providing others the solid foundations for
                    Homeopathic Practice!
                  </p>
                  <p>
                    I sincerely hope learning from SHAH will take Homeopaths brighter, higher & stronger in their
                    mission of healing the sick.
                  </p>
                  <p>
                    <i>Hail Hahnemann. Hail Homeopathy.</i>
                  </p>

                  <p>
                    {' '}
                    <b>Dr. Krutik Shah</b>, M.D. (Organon of Medicine) <br />
                    <b>Proud Child of Dr. Samuel Hahnemann </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <WhyStudyShah />
          {/* <Reviews /> */}

          <ReviewImageSlider />

          {/* <Trainer /> */}
          <CallToAction />
          <div className='desktop'>
            <div className='combine'>
              <div className='headMain'>
                <div className='headings'>
                  {/* <h2>
                    Original Homeopathy is <br />
                    <span>Simple Homeopathy& Easy Homeopathy</span>
                  </h2> */}
                  <ul style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <li style={{ fontSize: '27px', color: 'white', fontWeight: 'bolder' }}>Original Homeopathy</li>
                    <li style={{ fontSize: '27px', color: 'white', fontWeight: 'bolder' }}>Simple Homeopathy</li>
                    <li style={{ fontSize: '27px', color: 'white', fontWeight: 'bolder' }}>Easy Homeopathy</li>
                  </ul>
                  <ul className='vl'>
                    <li>&nbsp;Learn</li>
                    <li>&nbsp;Love</li>
                    <li>&nbsp;Lead</li>
                  </ul>
                  <button onClick={() => (window.location.href = 'signup')}>BE The HOMEOPATH</button>
                  <br />
                </div>
              </div>
              <div className='photo'>
                <img src='images/groupPhoto.jpg' />
              </div>
            </div>
          </div>

          {/* <BlogsGrid/> */}

          <Footer />
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  }
}

export default Home
