import React, { Suspense, lazy } from 'react'
import '../styles/nav.scss'

import useWindowDimesions from '../components/useWindowDimesions'
import SplashScreen from './SplashScreen'
import { Helmet } from 'react-helmet'

// import Footer from '../components/Footer'
const Footer = lazy(() => import('../components/Footer'))
// import Hero from '../components/Hero'
const Hero = lazy(() => import('../components/Hero'))

// import MobileNavBar from '../components/MobileNavBar'
const MobileNavBar = lazy(() => import('../components/MobileNavBar'))
// import DesktopNavBar from '../components/DesktopNavBar'
const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))

function TermsContent() {
  return (
    <>
      <div className='terms'>
        <p>SHAH PRIVACY POLICY / TERMS AND CONDITIONS</p>
        <p>Welcome to www.samuelhahnemann.org,</p>
        <p>
          Please read these terms and conditions carefully as they outline the rules and regulations for the use of Dr.
          Krutik Shah's Website. Your use of the website shall constitute lawfully binding agreement between you and
          SHAH. By entering/viewing this website, we assume you accept these terms and conditions stated hereinafter.
        </p>

        <p>1. DEFINITIONS</p>
        <p style={{ marginLeft: 12 }}>
          i. "Agreement" is a reference to these Terms and Conditions, the Privacy Policy, any registration form and
          payment instructions provided to you.
        </p>

        <p style={{ marginLeft: 12 }}>
          ii. “You”, “your”, “User” and “yours” are references to you, the person accessing this website
          www.samuelhahnemann.org and choosing to pursue program on our e-learning platform through the website.
        </p>

        <p style={{ marginLeft: 12 }}>
          iii. “Academy”, “we”, “us”, “our”, are references to the SHAH, a E-Learning having its registered office at
          Samuel Hahnemann Academy for Homeopathy - SHAH, Anand - 388001 , Gujarat, India; which owns and operates the
          website www.samuelhahnemann.org
        </p>

        <p style={{ marginLeft: 12 }}>
          iv. “Party”, “Parties”, or “Us”, may also refer to both you/User and ourselves/academy.
        </p>

        <p style={{ marginLeft: 12 }}>
          v. "Services" shall mean the training and other courses offered by the academy on this website or any medium
          that is reached through this website.
        </p>

        <p style={{ marginLeft: 12 }}>
          vi. “Website” shall mean the portal provided by the Academy for the purposes of the User.
        </p>

        <p style={{ marginLeft: 12 }}>
          vii. “Intellectual Property” means all trademark, copyright, creativity, concepts, unique business models,
          secrets and similar rights developed by the Academy.
        </p>

        <p style={{ marginLeft: 12 }}>
          viii. “Law” is regulations, requirements and guidelines of the government, court and authorities with which
          either party is legally required to comply with respect to this terms & conditions.
        </p>

        <p>2. COOKIES & PRIVACY POLICY:</p>

        <p style={{ marginLeft: 12 }}>
          {' '}
          i. We employ the use of cookies. By accessing this website, you agree to use cookies in agreement with our
          Privacy Policy.
        </p>

        <p style={{ marginLeft: 12 }}>
          ii. You understand that this website uses cookies to let us retrieve your/user’s details for each visit. You
          further understand that cookies are used by our website to enable the functionality of certain areas to make
          it easier for people like you who visit our website. Some of our affiliate/advertising partners may also use
          cookies.
        </p>

        <p style={{ marginLeft: 12 }}>
          iii. You understand that we follow a standard procedure of using log files which log visitors when they visit
          this website as a part of hosting services’ analytics. You understand that the information collected by log
          files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time
          stamp, referring/exit pages, and possibly the number of clicks. The purpose of the information is for
          analysing trends, administering the site, tracking users’ movement on the website, and gathering demographic
          information.
        </p>
        <p style={{ marginLeft: 12 }}>
          iv. You understand that third-party ad servers or ad networks uses technologies like cookies, JavaScript, or
          Web Beacons that are used in their respective advertisements and links that appear on Dr. Krutik Shah's
          Website, which are sent directly to users’ browser. They automatically receive your IP address when this
          occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to
          personalize the advertising content that you see on this website. We have no access to or control over these
          cookies that are used by third-party advertisers.
        </p>
        <p style={{ marginLeft: 12 }}>
          v. We assure that all collected information will be kept confidential, protective and encrypted and that
          collection of such data are as per Information Technology (Reasonable security practices and procedures and
          sensitive personal data or information) Rules, 2011.
        </p>
        <p style={{ marginLeft: 12 }}>
          vi. Where we have requested any information from you, you agree to provide us with accurate and complete
          information. We undertake to process all personal data obtained through use of the website in accordance with
          our Privacy Policy and law.
        </p>

        <p>3. WARRANTIES:</p>

        <p style={{ marginLeft: 12 }}>
          i. With reference to your posts or any matter that you publish on our website, you understand and warrant that
          you have all necessary licenses and consents to do so and such post/matter does not breach/infringe any
          intellectual property right, including without limitation copyright, patent or trademark of any third party.
        </p>
        <p style={{ marginLeft: 12 }}>
          ii. You warrant that your comments/matter will not contain any defamatory, offensive, indecent or otherwise
          unlawful material which is an invasion of privacy and such comments will not be used to present any commercial
          activities or unlawful activity.
        </p>
        <p style={{ marginLeft: 12 }}>
          iii. You hereby grant SHAH academy a non-exclusive license to use, reproduce, edit and authorize others to
          use, reproduce and edit any of your comments in any and all forms, formats or media as well as hyperlinking to
          our Content.
        </p>
        <p style={{ marginLeft: 12 }}>
          iv. The following organizations may link to our Website without prior written approval:
          <br />
          a) Government agencies <br />
          b) Search engines <br />
          c) News organizations
        </p>

        <p style={{ marginLeft: 12 }}>
          v. You understand that online directory of academies may link to this website and we may approve other link
          requests from the following types of organizations: <br />
          a) dot.com community sites
          <br />
          b) associations or other groups representing charities
          <br />
          c) educational institutions and homeopathy (medical) associations
        </p>

        <p style={{ marginLeft: 12 }}>
          vi. You understand that we will approve link requests from these organizations if we decide that: (a) the link
          would not make us look unfavourably to ourselves or to our accredited institutions; (b) the organization does
          not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates
          the absence of SHAH academy; and (d) the link is in the context of general resource information.
        </p>
        <p style={{ marginLeft: 12 }}>
          vii. You understand that these organizations may link to our home page so long as the link: (a) is not in any
          way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its
          products or services; and (c) fits within the context of the linking party’s site.
        </p>
        <p style={{ marginLeft: 12 }}>
          viii. If you are interested in linking to our website, you must inform us by sending an e-mail to SHAH
          academy. Please include your name, your organization name, contact information as well as the URL of your
          site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to
          which you would like to link and you must then wait 2-3 weeks for a response from us.
        </p>
        <p style={{ marginLeft: 12 }}>
          ix. You understand that no use of SHAH academy logo or other artwork will be allowed for linking absent a
          trademark license agreement or a copyright license agreement.
        </p>

        <p>4. RESERVATIONS:</p>
        <p style={{ marginLeft: 12 }}>
          i. Parts of this website offer an opportunity for users to post and exchange opinions and information in
          certain areas of the website. We do not filter, edit, publish or review comments prior to their presence on
          the website and any/all comments do not reflect the views and opinions of SHAH academy, its agents and/or
          affiliates. You understand and agree that comments reflect the views and opinions of such person who posts
          such views and opinions.{' '}
        </p>
        <p style={{ marginLeft: 12 }}>
          ii. We shall not be liable for the comments or for any liability, damages or expenses caused and/or suffered
          as a result of any use of and/or posting of and/or appearance of the comments on this website.
        </p>
        <p style={{ marginLeft: 12 }}>
          iii. We reserve the right to monitor all comments and to remove any comments which can be considered
          inappropriate, offensive or causes breach of these Terms and Conditions.
        </p>
        <p style={{ marginLeft: 12 }}>
          iv. We may alter or vary the Terms and Conditions at any time without notice to you. We reserve the right to
          change or revise the Terms and Conditions at any time by posting changes or a revised Terms and Conditions on
          this Website without any notice. The changed or revised Terms and Conditions will be effective immediately
          after it is posted on this Website.
        </p>
        <p style={{ marginLeft: 12 }}>
          v. The contents on this website including text, photographs, designs, artworks, etc. are owned by us and any
          intellectual property arising out of such text, photographs, designs, artworks, etc. belongs to us and nobody
          else. You/Users are not allowed (in any manner whatsoever) to copy, modify, reproduce, transmit, distribute,
          or create derivative works of Content or information without permission from us as they are subject to our
          intellectual property/copyright.
        </p>
        <p style={{ marginLeft: 12 }}>
          vi. The trademarks, brand name, logos, and service marks displayed on the Website are owned & controlled by us
          and any intellectual property arising out of such trademarks, brand name, logos, and service marks belongs to
          us and nobody else.{' '}
        </p>
        <p style={{ marginLeft: 12 }}>
          vii. Any feedback should be addressed to the email
          <a href='./contact'> support@samuelhahnemann.org </a>
          and our representatives remain available on call +91 75677 56443 from Monday to Friday (9 am to 6 pm IST). For
          any queries, please refer to the Frequently Asked Questions at (the link)
          <a href='./faqs'> www.samuelhahnemann.org/faqs</a>
        </p>

        <p>5. COURSES/PROGRAMS</p>

        <p style={{ marginLeft: 12 }}>
          i. We submit that studying all programs is a training activity under process of the academy and the programs
          shall be concentrated in the subject of original homeopathy. This portal is an e-learning platform which
          offers access to have excellent experience to gain knowledge in the subject of original homeopathy. The portal
          is accessible from all over the world and in that sense this e-learning platform is truly global.
        </p>
        <p style={{ marginLeft: 12 }}>
          ii. Our programs are divided in to various sub-areas within the subject of original homeopathy which are in
          the field of organon of medicine. All the programs of the academy are chaired and led by the dynamic doctor
          Krutik Shah who has scaled supreme heights in the field of homeopathy.{' '}
        </p>
        <p style={{ marginLeft: 12 }}>
          iii. All the programs & courses offered by the portal are test oriented programs and the users of the
          e-learning platform shall have to pass online exams or equivalent tests as per their choice of the program.
          The user has to complete the full program and there shall be no exception to his/her attendance and if he/she
          does not follow the program as per the directions of the platform then the access of the user to such program
          shall stand suspended after three warning which shall be documented in form of mails/messages.
        </p>
        <p style={{ marginLeft: 12 }}>
          iv. The user has to know that he/she shall be required to follow a protocol on this e-learning platform
          throughout his/her program including the completion of the quiz. <br />
          All the courses offered by SHAH are vocational courses / certificate courses / Continuous Homeopathic Medical
          Education (CHME) courses to boost up the skills of students and homeopaths based on the concept of “Learn
          Anytime Anywhere for Specific Hours.” They are different from the Government of India’s Homeopathic Diploma,
          Degree, Post Graduate, and Ph.D. Courses. <br />
          The certificate provided to the participant at the end of the course globally does not automatically confer a
          legal license to practice homeopathy. Participants must comply with the respective laws of their country.
        </p>
        <p style={{ marginLeft: 12 }}>
          v. The user has to understand that the entire program and the quiz will be conducted online on an e-platform
          of SHAH. The user has to maintain necessary equipment enabling him/her to successfully
          participate/deliberate/complete the program on e-learning platform of the academy.
        </p>
        <p style={{ marginLeft: 12 }}>
          vi. The user understands and agrees that all his/her videos will be recorded and the same shall be audited by
          the academy for accessing the user’s progress in the program. Recordings will be kept for a period of one year
          and will be deleted afterwards. The user is expected maintain the decorum of quiz where any third-party help
          in any manner is not allowed or any copying is not permissible. Such actions will call for user’s suspension
          from the program with immediate effect.
        </p>
        <p style={{ marginLeft: 12 }}>
          vii. The quiz shall be conducted periodically at the end of specific chapter(s) and detailed schedule shall be
          available upon selection in program.
        </p>
        <p style={{ marginLeft: 12 }}>
          viii. The users have to maintain absolute confidentiality over all such cases that are discussed or studied
          for e-learning platform. The users cannot divulge such data anywhere including the patient data and if done
          so, such user will be prosecuted as per the appropriate laws.
        </p>
        <p style={{ marginLeft: 12 }}>
          ix. The user understands and agrees that he/she shall have access to audios/videos provided by the platform
          including all other materials that are qualified to be accessed as per the program. Please note that your
          login/username and password for the e-learning platform is strictly confidential and cannot be shared with
          anyone else.
        </p>
        <p style={{ marginLeft: 12 }}>
          x. The user understands and agrees that the course will get open once the fees are paid and relevant documents
          are submitted. The candidate will be suspended from the platform anytime without prior notice if
          administrators of SHAH finds insufficient or inappropriate or manipulated documentations as this documents
          will be used to send the course complete certificate and the badge of honour from SHAH to your doorstep.
        </p>
        <p style={{ marginLeft: 12 }}>
          xi. The user understands and agrees that all payments made to the academy shall be bank transfers/e-payments
          as per Indian currency (INR) for Indian students only, and USD for Homeopathic Practitioners and International
          students. You can opt “No Cost EMI option” for getting comfortable payment options. 100% money will be
          refunded with 5 days if the user isn’t satisfied with the quality of education. Fees for all the courses are
          inclusive of Indian Taxes.
        </p>
        <p style={{ marginLeft: 12 }}>
          xii. Situations include any war, civil commotion, strike, governmental action, lockout, accident, epidemic,
          natural calamities, or any other event of any nature or kind which are beyond the control during such cases
          non-performance will not be granted.
        </p>

        <p>6. COURSES CANCELLATION AND MONEY REFUND POLICY:</p>
        <p style={{ marginLeft: 12 }}>
          i. The user is entitled to cancel the order within 5 days without giving any reason for doing so.
        </p>
        <p style={{ marginLeft: 12 }}>
          ii. The deadline for canceling an order is 5 days from the date on which the courses are received.
        </p>
        <p style={{ marginLeft: 12 }}>
          iii. In order to exercise the right of cancellation - The user must inform us the decision by means of a clear
          statement by email @ support@samuelhahnemann.org
        </p>
        <p style={{ marginLeft: 12 }}>
          iv. We will reimburse the money no later than 15 days from the day on which we receive the returned courses.
          We will use the same means of payment as the user used to purchase, and user will not incur any fees for such
          reimbursement.
        </p>

        <p>7. DISCLAIMER & LIABILITY</p>

        <p style={{ marginLeft: 12 }}>
          i. We submit that this website has true, factual and pure content. There is no malice in any information or
          literature available on the website. We shall not be liable for any loss that the buyer faces by taking any
          actions after reading the material of this website.
        </p>
        <p style={{ marginLeft: 12 }}>
          ii. The academy will not be held responsible if there is any delay (direct or indirect) in procuring or
          delivering the Services in any unforeseen conditions which are outside our reasonable control.{' '}
        </p>
        <p style={{ marginLeft: 12 }}>
          iii. The liability of website, its agents, employees, subcontractors and supplier with respect to any and all
          claims arising out of the performance or non-performance of our obligations in connection with the use of the
          information provided under the Contract, or the rendition of services hereunder, contract, negligence, strict
          liability or otherwise, shall not exceed, in the aggregate, the net purchase price for such products.{' '}
        </p>
        <p style={{ marginLeft: 12 }}>
          iv. Adequate care has been taken to ensure that the information available on the website is correct and error
          free. However, we cannot warrant that use of the website will be error free or is fit for the purpose, or is
          timely, or that the defects will be corrected, or that the site or the server that makes it available are free
          of viruses or bugs or represents the full functionality, accuracy, reliability of the website and we do not
          make any warranty whatsoever, whether express or implied, relating to fitness for purpose, or accuracy.
        </p>
        <p style={{ marginLeft: 12 }}>
          v. By accepting these terms of use you agree to relieve us from any liability whatsoever arising from your use
          of any information provided on the website or made available from any third party.
        </p>
        <p style={{ marginLeft: 12 }}>
          vi. We shall not be held liable for any failure or delay in performing services or delivering Services where
          such failure arises as a result of any act or omission, which is outside our reasonable control such as
          unprecedented circumstances, overwhelming and unpreventable events caused directly and exclusively by forces
          of nature that can be neither anticipated, nor controlled, nor prevented by the exercise of prudence,
          diligence, and care, including but not limited to: war, riot, civil commotion; pandemic; epidemic; lockout;
          government guidelines; compliance with any law or governmental order, rule, regulation or direction and acts
          of third parties “Force Majeure”.{' '}
        </p>

        <p>8. INTELLECTUAL PROPERTY & SECURITY</p>
        <p style={{ marginLeft: 12 }}>
          i. We (academy) retain the absolute right to use the intellectual property including copyrights, trademarks,
          designs, artworks and all other IPR rights for itself and retains the right to grant it to other third
          parties. All intellectual property including copyrights, trademarks, designs, artworks and all other IPR
          rights will remain our property.
        </p>
        <p style={{ marginLeft: 12 }}>
          ii. The user/buyer shall not use the services offered by the website, in a way which infringe the intellectual
          property right of the academy. In case of any infringement of Intellectual Property Rights, you/buyer will be
          solely responsible for any claim arising out of it. We reserve all the right to terminate the account and
          remove the infringing material of any buyer who is alleged to infringe any intellectual property right.
        </p>
        <p style={{ marginLeft: 12 }}>
          iii. Every audio, video and document of this platform is been encrypted with Forensic watermarking. Cut, copy,
          reproduction, forward and allied activities in any form whatsoever of the work either in parts or in full, is
          punishable offence, which you may please note. All the copyright & literature related to this work qualifies
          as protected and proprietary data. No part of this work may be reproduced or utilised in any form/by any
          means, electronic/mechanical including photocopying, recording/by any information storage & retrieval system
          without permission in writing. Hence, we would encourage the users to refrain from any misadventures of
          causing any breach to the same. Any breach will result in legal action which please note.
        </p>

        <p>9. NOTICES & CORRESPONDENCE</p>

        <p style={{ marginLeft: 12 }}>
          i. If either party shall desire or be required to give any notice/documents, such notice/documents shall be
          deemed to be validly served if (practiced in either of these forms of communication): (1) personally
          delivered; or (2) deposited by post (return receipt requested); or (3) sent electronically via email.{' '}
        </p>
        <p style={{ marginLeft: 12 }}>
          ii. All dealings, correspondence and contacts between us shall be made or conducted in the English language.
        </p>

        <p>10. ARBITRATION & JURISDICTION</p>

        <p style={{ marginLeft: 12 }}>
          i. If parties are unable to reach a solution within a period of thirty (30) days, then upon written notice by
          any other party to the other, the dispute, claim, question or difference shall be resolved by arbitration. A
          sole arbitrator appointed with the mutual consent of both parties to be resolved by arbitration as per the
          Arbitration & Conciliation Act, 1996 and such proceedings shall be conducted at City of Anand in Gujarat State
          (INDIA), in accordance with their Arbitration Rules.
        </p>
        <p style={{ marginLeft: 12 }}>
          ii. These Terms and Conditions shall be governed by and construed in accordance with the laws of India. The
          parties hereto submit to the exclusive jurisdiction of the courts of Anand at Gujarat in India.
        </p>
      </div>
    </>
  )
}

const Terms = () => {
  const { width } = useWindowDimesions()

  if (width < 999) {
    return (
      <>
        <Helmet>
          <title>E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy - Terms and Conditions</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'E-Learning @ SHAH',
              name: 'E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <MobileNavBar />

          <Hero title='Terms & Conditions' />
          <TermsContent />
          <Footer />
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy - Terms and Conditions</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'E-Learning @ SHAH',
              name: 'E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <div className='desktop'>
            <DesktopNavBar />

            <Hero title='Terms & Conditions' />
            <TermsContent />

            <Footer />
          </div>
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you.It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  }
}

export default Terms
